import React, { Component } from "react";

import {
  CardHeader,
  CardTitle,
  FormGroup,
  Button,
  Card,
  CardBody,
  Col,
  Row
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateAuth } from "../../../actions/auth";

import {
  getEmailMask,
  getFormatCountDown,
  getChangeEmail
} from "./Codigo.extend";
import PinInput from "react-pin-input";

import image1 from "../../../assets/img/brand/login-background-1.jpg";
import CircularProgressbar from "react-circular-progressbar";

import { callServicesLogin } from "../../../services/Services";
import OP_CONST from "../../../services/OperationConstants";
import TEXTS from "../../../utils/TextContent";
import { showModal, showLoading, closeLoading } from "../../../utils/showModal";
import { assets } from "../../../assets";

class Codigo extends Component {
  constructor() {
    super();
    this.state = {
      otp: "",
      auth: true,
      formComplete: false,
      otpIncorrect: true,
      countdown: 300,
      percent: 0,
      changeEmail: false
    };
    this.timer = 0;
    this.max = 300;
    this.validateOtp = this.validateOtp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.countDown = this.countDown.bind(this);
    this.restart = this.restart.bind(this);
  }
  handleChange({ target }) {
    this.setState({
      [target.name]: target.value
    });
  }
  goBack() {
    //this.props.history.goBack();
  }
  async getOtp() {
    //var json = JSON.parse(atob(localStorage.getItem("user-data")));
    //var params = '?TipoDoc=' + json.tipoDoc + '&Doc=' + json.doc + '&EnviarOtp=true';
    const user = this.props.auth.user;
    console.log("user-->",user);
    showLoading();
    let response;
    try {
      response = await callServicesLogin(
        OP_CONST.GET,
        "usuario?TipoDoc=" +
          user.tipoDoc +
          "&Doc=" +
          user.doc +
          "&enviarOTP=true",
        ""
      );
    } catch (error) {}
    closeLoading();
    if (response.data.responseCode !== 200) {
      this.props.history.push("/login");
      setTimeout(() => {
        showModal(response.data.message, "LO SENTIMOS", assets.important);
      }, 300);
    }
  }
  async validateOtp() {
    //var json = JSON.parse(atob(localStorage.getItem("user-data")));
    //json.otp = this.state.otp;
    const user = this.props.auth.user;

    var otpInfo = {
      TipoDoc: user.tipoDoc,
      Doc: user.doc,
      Code: this.state.otp
    };
    var verifyCode;
    try {
      showLoading();
      verifyCode = await callServicesLogin(OP_CONST.POST, "usuario/", otpInfo);
    } catch (error) {
      closeLoading();
      return;
    }
    closeLoading();

    if (verifyCode.data.data != null) {
      await this.props.updateAuth({
        authenticated: true,
        auth: { token: verifyCode.data.data }
      });
      this.props.history.push("/dashboard");
    } else {
      this.pinInput.clear();
      showModal(TEXTS.ERROR_PASS, "LO SENTIMOS", assets.correoActualizar);
      this.setState({
        loading: false,
        otp: ""
      });
    }
  }
  closeError() {
    this.setState({
      otpIncorrect: true,
      changeEmail: false,
      connectError: false,
      otp: ""
    });
  }
  restart() {
    this.setState({
      countdown: this.max,
      percent: 0
    });

    this.getOtp();
    this.timer = setInterval(this.countDown, 1000);
  }
  startTimer() {
    if (this.timer == 0 && this.state.countdown > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
    this.getOtp();
  }
  countDown() {
    let seconds = this.state.countdown - 1;
    /* let perc = (100 - (seconds * 100) / this.max);
    perc = 45 + (perc / 1.8); */

    let perc = (seconds * 100) / this.max;

    this.setState({
      countdown: seconds,
      percent: perc
    });
    if (seconds == 0) {
      clearInterval(this.timer);
      this.timer = 0;
      this.setState({
        countdown: 0
      });
    }
  }
  componentWillMount() {}
  componentWillUnmount() {
    closeLoading();
    clearInterval(this.timer);
  }
  componentDidMount() {
    if (!this.props.auth.user) {
      this.props.history.push("/login");
      return;
    }
    this.startTimer();
    var masks = getEmailMask(
      this.props.auth.user.email,
      this.props.auth.user.celular
    );
    this.setState({ eMask: masks.eMask, pMask: masks.pMask });
    this.pinInput.clear();
    //this.getOtp();
  }

  openChangeEmail() {
    showModal(getChangeEmail(), "CAMBIAR MEDIO DE ENVÍO", assets.telefonoVerde);

    this.setState({
      changeEmail: true,
      loading: false
    });
  }

  render() {
    if (!this.props.auth.user) {
      return null;
    }

    return (
      <>
        <div className="Content">
          <Row>
            <Col lg={2} xs={12} />
            <Col lg={8} xs={12}>
              <Row className="login-page">
                <Col md={6} xs={12} className="no-padding">
                  <Card className="card-user login-content">
                    <CardHeader className="login-header no-margin">
                      {/* <div className="logo">
                      <img src={logo}></img>
                    </div> */}
                      <CardTitle
                        className="title-complex color-palette-green text-bold"
                        align="center"
                      >
                        INGRESO DE C&Oacute;DIGO
                      </CardTitle>
                      <hr />
                      <CardTitle
                        className="subtitle-complex login-padding-text"
                        align="center"
                      >
                        Para continuar debe ingresar el c&oacute;digo que se
                        enviará a los medios registrados
                      </CardTitle>
                      <br />
                      {!!this.state.eMask ? (
                        <CardTitle
                          style={{ fontSize: 17 }}
                          className="title color-palette-green text-bold"
                          align="center"
                        >
                          {this.state.eMask}
                        </CardTitle>
                      ) : null}
                      {!!this.state.pMask ? (
                        <CardTitle
                          style={{ fontSize: 17 }}
                          className="title color-palette-green text-bold"
                          align="center"
                        >
                          {this.state.pMask}
                        </CardTitle>
                      ) : null}
                      <p
                        className="link-reload-otp-show no-margin"
                        onClick={() => this.openChangeEmail()}
                      >
                        Cambiar medio de envío
                      </p>
                    </CardHeader>
                    <CardBody className="login-padding">
                      <form>
                        <FormGroup>
                          <PinInput
                            length={6}
                            focus={true}
                            type="numeric"
                            value={this.state.otp}
                            ref={n => (this.pinInput = n)}
                            onChange={(value, index) => {
                              this.state.otp = value;
                            }}
                            onComplete={() =>
                              this.setState({ formComplete: true })
                            }
                            name="otp"
                            id="otp"
                          />
                        </FormGroup>
                        {/*   <Row>
                        {message}
                      </Row> */}
                        <div style={{ textAlign: "center" }}>
                          <CircularProgressbar
                            className="inverted"
                            counterClockwise={false}
                            background
                            percentage={this.state.percent}
                            text={""}
                          />
                          <div
                            className="color-palette-lightgray circular-text"
                            style={{ fontSize: "13px" }}
                          >
                            Expira en
                            <div
                              className="color-palette-green text-bold"
                              style={{ fontSize: "17px" }}
                            >
                              {getFormatCountDown(this.state.countdown)}
                            </div>
                            segundos
                            <p
                              className={
                                this.state.countdown == 0
                                  ? "link-reload-otp-show"
                                  : "link-reload-otp-hide"
                              }
                              onClick={this.restart}
                            >
                              No recibí el código, volver a enviar
                            </p>
                          </div>
                        </div>

                        <Row className="login-buttons-2">
                          <div className="update ml-auto mr-auto">
                            <Button
                              className={[
                                "test-btnlogin",
                                !this.state.formComplete
                                  ? "btn-disabled"
                                  : "btn-ecopetrol"
                              ].join(" ")}
                              disabled={!this.state.formComplete}
                              onClick={this.validateOtp}
                              round="true"
                            >
                              Ingresar
                            </Button>
                          </div>
                        </Row>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
                <Col
                  md={6}
                  style={{ backgroundImage: "url(" + image1 + ")" }}
                  className="login-background"
                />
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.auth.auth
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      updateAuth
    }
  )(Codigo)
);
