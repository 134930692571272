import React from "react";
import { RenderCustomModal } from "../utils/Login.modal";
import PubSub from 'pubsub-js'
import { layoutConsts } from "./ModalLayout";

// Mensaje para formulario
const ErrorMessage = props => {
  if(!props.error){
    return null;
  }
  return (
    <RenderCustomModal
      visible={props.error}
      title={props.error.title}
      message={props.error.message}
      icon={props.error.icon}
      buttons={[
        {
          title: "Aceptar",
          className: 'btn-eco-petrol-2',
          onClick: () => {
            props.updateAuth({error: null});
            PubSub.publish(layoutConsts.CLOSE_MODAL);
          }
        }
      ]}
    />
  );

}
export default ErrorMessage;