// Compatibilidad con IE11
import "babel-polyfill";
import "filepond-polyfill";
//import 'react-app-polyfill/ie11';

import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import configureStore from "./store/configureStore";
import Root from "./containers/Root";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";

const store = configureStore;
const msalInstance = new PublicClientApplication(msalConfig);

render(
  <MsalProvider instance={msalInstance}>
  <Router>
    <Root store={store} />
  </Router>
  </MsalProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
