import abajoGris from "./assets/images/abajogris.png";
import adminMagazineGray from "./assets/images/admin_magazine_gray.png";
import adminMagazineGreen from "./assets/images/admin_magazine_green.png";
import adminNotificationGray from "./assets/images/admin_notification_gray.png";
import adminNotificationGreen from "./assets/images/admin_notification_green.png";
import adminQuestionsGray from "./assets/images/admin_questions_gray.png";
import adminQuestionsGreen from "./assets/images/admin_questions_green.png";
import agendaFechaVerde from "./assets/images/agenda-fecha-verde.png";
import agendaHoraVerde from "./assets/images/agenda-hora-verde.png";
import agendarMegaVerde from "./assets/images/agendar-mega-verde.png";
import agendarOgaVerde from "./assets/images/agendar-oga-verde.png";
import appointmentDate from "./assets/images/appointment-date.png";
import arribaGris from "./assets/images/arribagris.png";
import cerrarSesionGris from "./assets/images/cerrar-2.png";
import chatGris from "./assets/images/chat-gris.png";
import chatVerde from "./assets/images/chat-verde.png";
import cierreSesion from "./assets/images/cierresesion.png";
import correoActualizar from "./assets/images/correo-actualizar.png";
import notFound from "./assets/images/disponibilidad.png";
import doctorFlechaAbajo from "./assets/images/doctor-flecha-abajo.png";
import doctorFlechaArriba from "./assets/images/doctor-flecha-arriba.png";
import estadoAsignar from "./assets/images/estadoasignar.png";
import estetoscopioVerde from "./assets/images/estetoscopio-verde.png";
import eventosVerde from "./assets/images/eventos-verde.png";
import grayDoctor from "./assets/images/gray-doctor.png";
import grayTooth from "./assets/images/gray-tooth.png";
import greenDoctor from "./assets/images/green-doctor.png";
import greenTooth from "./assets/images/green-tooth.png";
import greenPhone from "./assets/images/greenPhone.png";
import iconoLoc from "./assets/images/iconoloc.png";
import important from "./assets/images/important.png";
import inicioVerde from "./assets/images/inicio-1.png";
import inicioGris from "./assets/images/inicio-2.png";
import ipsAsignar from "./assets/images/ips_asignar.png";
import sendEmailVerde from "./assets/images/mail_green.png";
import sendEmailGris from "./assets/images/mail_grey.png";
import medicamentoAsignar from "./assets/images/medicamentoasignar.png";
import mega from "./assets/images/MEGA.png";
import mega2 from "./assets/images/MEGA2.png";
import messageChatGris from "./assets/images/messageChatGris.png";
import misCitasGris from "./assets/images/miscitasgris.png";
import misCitasVerde from "./assets/images/miscitasverde.png";
import noticiasVerde from "./assets/images/noticias-verde.png";
import oga from "./assets/images/OGA.png";
import oga2 from "./assets/images/OGA2.png";
import personMedium from "./assets/images/person-medium.png";
import personSmall from "./assets/images/person-small.png";
import preguntasFrecuentesVerde from "./assets/images/preguntas-frecuentes-verde.png";
import questionsGris from "./assets/images/questions_grey.png";
import redMedicaVerde from "./assets/images/red1.png";
import redMedicaGris from "./assets/images/red2.png";
import revistaDigitalVerde from "./assets/images/revista-digital.png";
import iconoLocSmall from "./assets/images/small/direccion.png";
import estadoAsignarSmall from "./assets/images/small/estado.png";
import agendaFechaVerdeSmall from "./assets/images/small/fecha.png";
import agendaHoraVerdeSmall from "./assets/images/small/hora.png";
import estetoscopioVerdeSmall from "./assets/images/small/mega.png";
import agendarOgaVerdeSmall from "./assets/images/small/oga.png";
import greenPhoneSmall from "./assets/images/small/telefono.png";
import successAsign from "./assets/images/success-assign.png";
import telefonoVerde from "./assets/images/telefono-verde.png";
import telefonoGris from "./assets/images/telefono2.png";
import adminUsersGray from "./assets/images/user_gray.png";
import adminUsersGreen from "./assets/images/user_green.png";
import wifiVerde from "./assets/images/wifi-verde.png";
import wrongAssign from "./assets/images/wrong-assign.png";
import logo from "./assets/img/brand/logo.png";

export const assets = {
  logo: {
    src: logo,
    style: { width: 291, height: 73 },
    alt: "Logo"
  },
  wifiVerde: {
    src: wifiVerde,
    style: { width: 87, height: 76 },
    alt: "Ícono wifi"
  },
  personMedium: {
    src: personMedium,
    style: { height: 70, width: 70 },
    alt: "Persona"
  },
  personSmall: {
    src: personSmall,
    style: { height: 48, width: 48 },
    alt: "Persona"
  },
  noticiasVerde: {
    src: noticiasVerde,
    style: { height: 56, width: 57 },
    alt: "Noticias"
  },
  inicioVerde: {
    src: inicioVerde,
    style: { height: 60, width: 64 }
  },
  revistaDigitalVerde: {
    src: revistaDigitalVerde,
    style: { height: 63, width: 73 },
    alt: "Revista digital"
  },
  preguntasFrecuentesVerde: {
    src: preguntasFrecuentesVerde,
    style: { height: 56, width: 57 },
    alt: "Preguntas frecuentes"
  },
  inicioGris: {
    src: inicioGris,
    style: { height: 60, width: 64 }
  },
  misCitasVerde: {
    src: misCitasVerde,
    style: { height: 59, width: 59 }
  },
  misCitasGris: {
    src: misCitasGris,
    style: { height: 60, width: 71 }
  },
  redMedicaVerde: {
    src: redMedicaVerde,
    style: { height: 60, width: 71 }
  },
  redMedicaGris: {
    src: redMedicaGris,
    style: { height: 60, width: 71 }
  },
  telefonoGris: {
    src: telefonoGris,
    style: { height: 54, width: 57 }
  },
  telefonoVerde: {
    src: telefonoVerde,
    style: { height: 56, width: 57 },
    alt: "Ícono teléfono"
  },
  cerrarSesionGris: {
    src: cerrarSesionGris,
    style: { height: 51, width: 50 }
  },
  ipsAsignar: {
    alt: "Ips",
    src: ipsAsignar,
    style: { width: 50, height: 63 }
  },
  wrongAssign: {
    alt: "Error",
    src: wrongAssign,
    style: { width: 167, height: 163 }
  },
  successAsign: {
    src: successAsign,
    style: { width: 167, height: 163 },
    alt: "Asignación exitosa"
  },
  arribaGris: {
    alt: "Expand",
    src: arribaGris,
    style: { width: 282, height: 134 }
  },
  abajoGris: {
    alt: "Expand",
    src: abajoGris,
    style: { width: 282, height: 134 }
  },
  estadoAsignar: {
    alt: "Expand",
    src: estadoAsignar,
    style: { width: 81, height: 96 }
  },
  greenPhone: {
    alt: "Expand",
    src: greenPhone,
    style: { width: 57, height: 56 }
  },
  iconoLoc: {
    alt: "Expand",
    src: iconoLoc,
    style: { width: 51, height: 51 }
  },
  agendaFechaVerde: {
    alt: "Date",
    src: agendaFechaVerde,
    style: { width: 59, height: 59 }
  },
  appointmentDate: {
    alt: "Ips",
    src: appointmentDate,
    style: { width: 50, height: 63 }
  },
  agendaHoraVerde: {
    src: agendaHoraVerde,
    alt: "Time",
    style: { width: 60, height: 58 }
  },
  estetoscopioVerde: {
    alt: "Mega",
    src: estetoscopioVerde,
    style: { width: 60, height: 58 }
  },
  agendarOgaVerde: {
    alt: "Oga",
    src: agendarOgaVerde,
    style: { width: 60, height: 58 }
  },
  greenTooth: {
    alt: "OGA",
    src: greenTooth,
    style: { width: 202, height: 202 }
  },
  grayTooth: {
    alt: "OGA",
    src: grayTooth,
    style: { width: 202, height: 202 }
  },
  greenDoctor: {
    alt: "MEGA",
    src: greenDoctor,
    style: { width: 135, height: 135 }
  },
  grayDoctor: {
    alt: "MEGA",
    src: grayDoctor,
    style: { width: 169, height: 173 }
  },
  oga: {
    alt: "OGA",
    src: oga,
    style: { height: 80, width: 80 }
  },
  mega: {
    alt: "MEGA",
    src: mega,
    style: { height: 80, width: 80 }
  },
  adminMagazineGray: {
    alt: "adminMagazineGray",
    src: adminMagazineGray,
    style: { height: 80, width: 80 }
  },
  adminMagazineGreen: {
    alt: "adminMagazineGreen",
    src: adminMagazineGreen,
    style: { height: 80, width: 80 }
  },
  adminNotificationGray: {
    alt: "adminNotificationGray",
    src: adminNotificationGray,
    style: { height: 80, width: 80 }
  },
  adminNotificationGreen: {
    alt: "adminNotificationGreen",
    src: adminNotificationGreen,
    style: { height: 80, width: 80 }
  },
  adminUsersGray: {
    alt: "adminUsersGray",
    src: adminUsersGray,
    style: { height: 80, width: 80 }
  },
  adminUsersGreen: {
    alt: "adminUsersGreen",
    src: adminUsersGreen,
    style: { height: 80, width: 80 }
  },
  adminQuestionsGray: {
    alt: "adminQuestionsGray",
    src: adminQuestionsGray,
    style: { height: 80, width: 80 }
  },
  adminQuestionsGreen: {
    alt: "adminQuestionsGreen",
    src: adminQuestionsGreen,
    style: { height: 80, width: 80 }
  },
  doctorFlechaArriba: {
    alt: "Flecha arriba",
    src: doctorFlechaArriba,
    style: { height: 28, width: 27 }
  },
  doctorFlechaAbajo: {
    alt: "Flecha abajo",
    src: doctorFlechaAbajo,
    style: { height: 28, width: 27 }
  },
  oga2: {
    alt: "OGA2",
    src: oga2,
    style: { height: 80, width: 80 }
  },
  mega2: {
    alt: "MEGA2",
    src: mega2,
    style: { height: 80, width: 80 }
  },
  eventosVerde: {
    alt: "Evento",
    src: eventosVerde,
    style: { height: 68, width: 68 }
  },
  agendarMegaVerde: {
    src: agendarMegaVerde,
    style: { height: 67, width: 64 },
    alt: "Ícono mega"
  },
  notFound: {
    src: notFound,
    style: { height: 87, width: 102 },
    alt: "Documento no encontrado"
  },
  medicamentoAsignar: {
    alt: "Histórico de Fórmulas Médicas",
    src: medicamentoAsignar,
    style: { height: 102, width: 76 }
  },
  correoActualizar: {
    src: correoActualizar,
    style: { height: 50, width: 73 },
    alt: "Ícono correo"
  },
  important: {
    alt: "Importante",
    src: important,
    style: { width: 89, height: 83 }
  },
  agendaFechaVerdeSmall: {
    alt: "Agenda fecha",
    src: agendaFechaVerdeSmall,
    style: { width: 21, height: 22 }
  },
  agendaHoraVerdeSmall: {
    alt: "Agenda hora",
    src: agendaHoraVerdeSmall,
    style: { width: 23, height: 22 }
  },
  estadoAsignarSmall: {
    alt: "Estado",
    src: estadoAsignarSmall,
    style: { width: 21, height: 25 }
  },
  greenPhoneSmall: {
    alt: "Teléfono",
    src: greenPhoneSmall,
    style: { width: 21, height: 21 }
  },
  iconoLocSmall: {
    alt: "Ubicación",
    src: iconoLocSmall,
    style: { width: 21, height: 21 }
  },
  estetoscopioVerdeSmall: {
    alt: "Mega",
    src: estetoscopioVerdeSmall,
    style: { width: 21, height: 22 }
  },
  agendarOgaVerdeSmall: {
    alt: "Oga",
    src: agendarOgaVerdeSmall,
    style: { width: 22, height: 27 }
  },
  cierreSesion: {
    alt: "Cierre de sesión",
    src: cierreSesion,
    style: { width: 104, height: 96 }
  },
  chatVerde: {
    alt: "Chat",
    src: chatVerde,
    style: { width: 22, height: 22 }
  },
  chatGris: {
    alt: "Chat",
    src: chatGris,
    style: { width: 22, height: 22 }
  },
  sendEmailVerde: {
    alt: "Enviar correo",
    src: sendEmailVerde,
    style: { width: 48, height: 34 }
  },
  sendEmailGris: {
    alt: "Enviar correo",
    src: sendEmailGris,
    style: { width: 49, height: 33 }
  },
  messageChatGris: {
    alt: "Chat",
    src: messageChatGris,
    style: { width: 32, height: 26 }
  },
  pqrsGris: {
    alt: "PQRS",
    src: questionsGris,
    style: { width: 48, height: 37 }
  }
};
