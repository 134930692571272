
import React, { Component } from "react";

class CalculatedIcon extends Component {
  constructor(props) {
    super(props);
    
  }

  render(){
    let dimensions = {};
    
    if(typeof this.props.size !== 'undefined'){
      dimensions.width = (this.props.style.width > this.props.style.height)? this.props.size : this.props.style.width * this.props.size / this.props.style.height 
      dimensions.height = (this.props.style.height > this.props.style.width)? this.props.size : this.props.style.height * this.props.size / this.props.style.width
    }
    const style = {...this.props.style, ...dimensions};
    
    return (
        <img 
            {...this.props}
            style={style}
            />
        )

}

}
export default CalculatedIcon;