import React, { Component } from "react";
import CalculatedIcon from "./CalculatedIcon";
import { Link } from "react-router-dom";
import { closeSideBar } from "../utils/sidebar";

export const sidebarConsts = {
  CLOSE: "SIDEBAR_CLOSE"
};

export const SideBarItem = props => (
  <ul className="nav flex-column">
    <li className="nav-item">
      <a className="nav-link active" href="#">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-home"
        >
          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
          <polyline points="9 22 9 12 15 12 15 22" />
        </svg>
        Dashboard <span className="sr-only">(current)</span>
      </a>
    </li>
  </ul>
);

export class SideBar extends Component {
  constructor(props) {
    super(props);
    this.onClickItem = this.onClickItem.bind(this);
    this.navigate = this.navigate.bind(this);
    this.state = {
      indexPage: this.calculateIndexPage()
    };
  }
  calculateIndexPage() {
    const { pathname } = this.props.location;

    for (var i = 0; i < this.props.navigationItems.length; i++) {
      var item = this.props.navigationItems[i];
      if (item.navigate === pathname) {
        return i;
      }
    }
  }
  onClickItem(item, index) {
    closeSideBar();
    if (item.navigate) {
      item.navigate(item.index);
      return;
    } else if (typeof item.onClick !== "undefined") {
      item.onClick();
    }

    this.setState({
      index
    });
  }
  async navigate(item, index) {
    this.props.history.push(item.navigate);
  }

  render() {
    var indexPage = this.calculateIndexPage();
    return (
      <ul className={"nav flex-column " + this.props.className}>
        {this.props.navigationItems.map((item, index) => {
          const onClick = item.navigate ? this.navigate : this.onClickItem;

          const externalLink = item.externalLink ? { target: "_blank" } : {};

          const link = item.navigate ? (
            <Link
              className="nav-link"
              to={item.navigate}
              onClick={() => {
                closeSideBar();
              }}
            >
              {!!item.icon && indexPage !== index && (
                <CalculatedIcon size={22} {...item.icon} />
              )}
              {!!item.iconSelected && indexPage === index && (
                <CalculatedIcon size={22} {...item.iconSelected} />
              )}
              {item.title} <span className="sr-only">(current)</span>
            </Link>
          ) : (
            <a
              className="nav-link"
              href={item.link}
              {...externalLink}
              onClick={() => {
                onClick(item, index);
              }}
            >
              {!!item.icon && indexPage !== index && (
                <CalculatedIcon size={22} {...item.icon} />
              )}
              {!!item.iconSelected && indexPage === index && (
                <CalculatedIcon size={22} {...item.iconSelected} />
              )}
              {item.title} <span className="sr-only">(current)</span>
            </a>
          );

          return (
            <li
              className={
                "nav-item " + (indexPage === index ? "active" : "inactive")
              }
              key={index.toString()}
            >
              {link}
            </li>
          );
        })}
      </ul>
    );
  }
}
