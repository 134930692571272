import React, { Component } from "react";
import { SideBar } from "./SideBar";
import { layoutConsts } from "./ModalLayout";
import PubSub from "pubsub-js";
import { assets } from "../assets";
import AuthService from "../services/AuthService";
import { clearGlobal, getGlobal } from "../services/Services";
import moment from "moment";
import { showModalErrorMegaOga } from "../utils/showModal";
export const sidebarConsts = {
  UPDATE: "SIDEBAR_UPDATE"
};
export default class CustomSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.closeSession = this.closeSession.bind(this);
    this.contactactenos = this.contactactenos.bind(this);
    this.update = this.update.bind(this);
    this.manageAppointmentNavigate = this.manageAppointmentNavigate.bind(this);

    this.state.navigationItems = this.getNavigationItems();
  }
  getNavigationItems() {
    if(window.location.pathname=='/directory'){
      return [{
        title: "Red de prestadores",
        link: "#",
        navigate: "/directory",
        icon: {
          ...assets.redMedicaGris,
          alt: "Red de prestadores"
        },
        iconSelected: {
          ...assets.redMedicaVerde,
          alt: "Red médica"
        }
        
      },
      {
        title: "Volver",
        link: "/dashboard",
        icon: {
          ...assets.cerrarSesionGris,
          alt: "Inicio"
        },
      }]
    }else{
    return !AuthService.isAdmin
      ? [
          {
            title: "Inicio",
            link: "#",
            navigate: "/dashboard",
            icon: {
              ...assets.inicioGris,
              alt: "Inicio"
            },
            iconSelected: {
              ...assets.inicioVerde,
              alt: "Inicio"
            }
          },
          {
            title: "Mis citas",
            link: "#",
            icon: {
              ...assets.misCitasGris,
              alt: "Mis citas"
            },
            iconSelected: {
              ...assets.misCitasVerde,

              alt: "Mis citas"
            },
            onClick: this.manageAppointmentNavigate
          },
          {
            title: "Contáctenos",
            link: "#",
            icon: {
              ...assets.telefonoGris,
              alt: "Contáctenos"
            },
            onClick: this.contactactenos
          },
          {
            title: "Chat",
            link:
              "http://190.144.220.178:8080/WebAPI812/Salud/HtmlChatFrameSet.jsp",
            externalLink: true,
            icon: {
              ...assets.messageChatGris,
              alt: "Chat"
            }
          },
          {
            title: "PQRS",
            link: "http://ecopetrol.force.com/IngresoQuejas",
            externalLink: true,
            icon: {
              ...assets.pqrsGris,
              alt: "Chat"
            }
          },
          {
            title: "Red de prestadores",
            link: "#",
            navigate: "/directory",
            icon: {
              ...assets.redMedicaGris,
              alt: "Red de prestadores"
            },
            iconSelected: {
              ...assets.redMedicaVerde,
              alt: "Red médica"
            }
          },
          {
            title: "Cerrar sesión",
            link: "#",
            icon: {
              ...assets.cerrarSesionGris,
              alt: "Inicio"
            },
            onClick: this.closeSession
          }
        ]
      : [
          {
            title: "Administrar usuarios",
            link: "#",
            navigate: "/admin/user",
            icon: {
              ...assets.adminUsersGray,
              alt: "Administrar usuarios"
            },
            iconSelected: {
              ...assets.adminUsersGreen,
              alt: "Administrar usuarios"
            }
          },
          {
            title: "Administrar FAQ",
            link: "#",
            navigate: "/admin/faq",
            icon: {
              ...assets.adminQuestionsGray,
              alt: "Administrar preguntas frecuentes"
            },
            iconSelected: {
              ...assets.adminQuestionsGreen,
              alt: "Administrar preguntas frecuentes"
            }
          },
          {
            title: "Administrar revistas",
            link: "#",
            navigate: "/admin/magazine",
            icon: {
              ...assets.adminMagazineGray,
              alt: "Administrar revistas"
            },
            iconSelected: {
              ...assets.adminMagazineGreen,
              alt: "Administrar revistas"
            }
          },
          {
            title: "Administrar notificaciones",
            link: "#",
            navigate: "/admin/notification",
            icon: {
              ...assets.adminNotificationGray,
              alt: "Administrar notificaciones"
            },
            iconSelected: {
              ...assets.adminNotificationGreen,
              alt: "Administrar notificaciones"
            }
          },
          {
            title: "Administrar correos",
            link: "#",
            navigate: "/admin/sendEmail",
            icon: {
              ...assets.sendEmailGris,
              alt: "Administrar correo"
            },
            iconSelected: {
              ...assets.sendEmailVerde,
              alt: "Administrar correo"
            }
          },

          {
            title: "Administrar red de prestadores",
            link: "#",
            navigate: "/admin/physician",
            icon: {
              ...assets.adminMagazineGray,
              alt: "Administrar red de prestadores"
            },
            iconSelected: {
              ...assets.adminMagazineGreen,
              alt: "Administrar medico"
            }
          },

          {
            title: "Cerrar sesión",
            link: "#",
            icon: {
              ...assets.cerrarSesionGris,
              alt: "Inicio"
            },
            onClick: this.closeSession
          }
        ];
      }
  }
  componentDidMount() {
    this.subscribeUpdate = PubSub.subscribe(sidebarConsts.UPDATE, this.update);
    this.update();
  }
  componentWillUnmount() {
    PubSub.unsubscribe(this.subscribeUpdate);
  }
  async update() {
    if (AuthService.isAdmin) {
      return;
    }
    var userInfo = await getGlobal("HeonData");
    await this.setState({ date: moment().millisecond(), userInfo });
    this.setState({ navigationItems: this.getNavigationItems() });
  }
  closeSession() {
    const isAdmin = AuthService.isAdmin;
    clearGlobal();
    this.props.closeSession(null, false);
    this.props.history.push(isAdmin ? "/adminlogin" : "/login");
  }
  manageAppointmentNavigate() {
    if (
      !this.state.userInfo ||
      !this.state.userInfo.mainUser ||
      Object.keys(this.state.userInfo.mainUser).length === 0
    ) {
      showModalErrorMegaOga();
    } else {
      this.props.history.push("/appointmentdashboard");
    }
  }
  contactactenos() {
    PubSub.publish(layoutConsts.SHOW_MODAL, {
      title: "CONTÁCTENOS",
      message:
        "Por favor comuníquese con nuestros asesores a la línea nacional gratuita 01-8000-915556.",

      icon: assets.telefonoVerde,
      buttons: [
        {
          title: "Aceptar",
          className: "btn-eco-petrol-2"
        }
      ]
    });
  }

  render() {
    return (
      <SideBar
        {...this.props}
        className="sidebar-links"
        navigationItems={this.state.navigationItems}
      />
    );
  }
}
