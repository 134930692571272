import { ValidateEmail } from "../../../services/Services";

export function getFormatCountDown(time) {   
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

export function getChangeEmail() {
    return "Para solicitar cambio del medio de envío, ya sea por correo electrónico o por mensaje de texto al celular, por favor comuníquese a la línea nacional gratuita 018000915556. ";
}

export function getOtpInvalid() {
    return "El código ingresado no corresponde al enviado a su correo electrónico";
}

export function getEmailMask(email, cellPhone) {

    if (email && ValidateEmail(email) == true) {
        var p1 = email.split('@')[0];
        var p2 = email.split('@')[1];
        var ln = p1.length - 3;
        var car = "";
        for (var i = 0; i < ln; ++i) car += "*";
        email = p1.substring(0, 3) + car;
        email = email + "@" + p2;
    } else {
        email = null
    }

    if (cellPhone && !isNaN(cellPhone)) {
        var car2 = "****";
        var mask2 = "";
        ln = cellPhone.length;
        p1 = cellPhone;
        mask2 = p1.substring(0, ln - 4) + car2;
    } else {
        mask2 = null;
    }
    return { eMask: email, pMask: mask2 };
}