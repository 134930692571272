import PubSub from "pubsub-js";
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row
} from "reactstrap";
import { assets } from "../../../assets";
import image1 from "../../../assets/img/brand/login-background-1.jpg";
import FormMessage from "../../../components/FormMessage";
import { layoutConsts } from "../../../components/ModalLayout";
import AuthService from "../../../services/AuthService";
import OP_CONST from "../../../services/OperationConstants";
import { callServicesLogin, ValidateEmail } from "../../../services/Services";
import { connectComponent } from "../../../utils/connect";
import CONST from "../../../utils/Constants";
import { closeLoading, showLoading, showModal } from "../../../utils/showModal";
import TEXTS from "../../../utils/TextContent";
import Validator from "../../../utils/validator";
import ReCAPTCHA from "react-google-recaptcha";
import "./Login.scss";

const ReCaptchaTerms = props => (
  <div className="recaptcha-text-container">
    <div className="title">Protegido por reCAPTCHA </div>
    <div className="links">
      <a href="https://policies.google.com/privacy" target="_blank">
        Privacidad
      </a>{" "}
      y{" "}
      <a href="https://policies.google.com/terms" target="_blank">
        Términos
      </a>
    </div>
  </div>
);
class Login extends Component {
  constructor() {
    super();
    this.state = {
      doctype: "",
      docnumber: "",
      showTerminos: false,
      showPoliticas: false,
      setPoliticas: false,
      formComplete: false,
      emailNotFound: false,
      userNotFound: true
    };
    this.validateLogin = this.validateLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDoc = this.handleChangeDoc.bind(this);
    this.handleChangeDocType = this.handleChangeDocType.bind(this);
    this.isValidDocument = this.isValidDocument.bind(this);
    this.isValidDocumentType = this.isValidDocumentType.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.setIfEmailStart = false;
    this.Validator = new Validator();
    //localStorage.removeItem("auth");
  }
  componentDidMount() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }

  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
    // https://github.com/dozoisch/react-google-recaptcha
    // https://product.hubspot.com/blog/quick-guide-invisible-recaptcha
    // http://emumba.com/blog/2016-12-07-setting-up-google-recaptcha-in-a-reactjs-app/
    // https://developers.google.com/recaptcha/docs/invisible
    setTimeout(() => {
      this.props.updateAuth(this.state.codigoData, false);
      this.props.history.push("/codigo");
    }, 300);
  }

  showHabeas(permission) {
    this.openModalPolitica(permission);
  }
  showTerminos() {
    this.openModalTerminos();
  }
  isValidDocument() {
    if (
      this.state.doctype === "CC" &&
      this.state.docnumber != "" &&
      !Number.isInteger(this.state.docnumber * 1)
    ) {
      return false;
    }
    return true;
  }
  isValidDocumentType(tag) {
    if (this.state.doctype === "") {
      return false;
    }
    return true;
  }
  resetForm() {
    this.Validator.reset("login-doctype");
    this.Validator.reset("login-docnumber");
    this.setState({
      doctype: "",
      docnumber: ""
    });
  }
  showModalEmailNotFound() {
    showModal(TEXTS.ERROR_EMAIL, "LO SENTIMOS", assets.correoActualizar);
    this.resetForm();
  }
  showModalUserNotFound() {
    showModal(TEXTS.ERROR_ID, "LO SENTIMOS", {
      ...assets.telefonoVerde,
      alt: "Documento no encontrado"
    });
    this.resetForm();
  }

  async validateLogin() {
    this.validateForm();
    var TipoDoc = this.state.user
      ? this.state.user.TipoDoc
      : this.state.doctype;
    var Doc = this.state.user ? this.state.user.Doc : this.state.docnumber;
    var login;
    try {
      showLoading();
      login = await AuthService.callAuthServicesLogin(TipoDoc, Doc);
    } catch (error) {
      closeLoading();
      return;
    }
    closeLoading();
    console.log("login--->",login)
    if (login.user != null) {
      var _emailValid =
        login.user.email == "" ||
        login.user.email == null ||
        !ValidateEmail(login.user.email)
          ? false
          : true;
      var celularValid =
        !login.user.Celular || isNaN(login.user.celular) ? false : true;

      if (!_emailValid && !celularValid) {
        this.showModalEmailNotFound();
        return;
      }
      if (login.attributesPending.length === 0) {
        this.captchaDemo.execute();
        this.setState({
          codigoData: {
            auth: { user: login.user, beneficiarios: login.beneficiarios }
          }
        });
        return;
      }
      if (typeof this["show" + login.attributesPending[0]] !== "undefined") {
        this["show" + login.attributesPending[0]](false);
      }
      this.setState({
        user: login.user
      });
    } else {
      this.showModalUserNotFound();
    }
  }
  validateForm() {
    this.Validator.registerChange("login-doctype");
    this.Validator.registerChange("login-docnumber");
  }
  isFormComplete() {
    if (
      this.state.doctype != "" &&
      this.state.docnumber != "" &&
      Number.isInteger(this.state.docnumber * 1)
    ) {
      this.setState({
        complete: true
      });
    } else {
      this.setState({
        complete: false
      });
    }
  }
  disabledButton() {
    if (this.state.doctype === "" || this.state.docnumber === "") {
      return true;
    }
    if (this.state.doctype === "CC") {
      return !(
        this.state.docnumber != "" && Number.isInteger(this.state.docnumber * 1)
      );
    }
    return false;
  }
  handleChangeDoc(event) {
    this.Validator.registerChange("login-docnumber");
    this.handleChange(event);
  }
  handleChangeDocType(event) {
    this.Validator.registerChange("login-doctype");
    this.handleChange(event);
  }
  handleChange({ target }) {
    this.setState(
      {
        [target.name]: target.value
      },
      () => {
        this.isFormComplete();
      }
    );
  }

  async closeModalTerminos(accept) {
    this.Validator.reset("login-doctype");
    this.Validator.reset("login-docnumber");

    this.setState({
      showTerminos: false,
      doctype: "",
      docnumber: ""
    });
    if (accept) {
      showLoading();
      await callServicesLogin(
        OP_CONST.PUT,
        "usuario/terminos/" + this.state.user.id,
        ""
      );
      closeLoading();

      this.validateLogin();
    }
  }
  openModalPolitica(accept) {
    PubSub.publish(layoutConsts.SHOW_MODAL, {
      title: "POLÍTICAS DE PRIVACIDAD",
      message: TEXTS.TEXT_HABEAS,
      buttons: [
        {
          title: "Aceptar",
          color: "primary",
          className: "btn-eco-petrol-2",
          onClick: () => {
            this.closeModalPolitica(true);
            PubSub.publish(layoutConsts.CLOSE_MODAL);
          }
        },
        {
          title: "Cancelar",
          color: "secondary",
          className: "btn-eco-petrol-2",
          onClick: () => {
            this.closeModalPolitica(false);
            PubSub.publish(layoutConsts.CLOSE_MODAL);
          }
        }
      ]
    });
    this.setState({
      showPoliticas: true,
      setPoliticas: accept
    });
    this.resetForm();
  }
  openModalTerminos() {
    PubSub.publish(layoutConsts.SHOW_MODAL, {
      title: "TÉRMINOS Y CONDICIONES",
      message: TEXTS.TEXT_TERMS,
      className: "modal-beneficiary-detail",
      buttons: [
        {
          title: "Aceptar",
          color: "primary",
          className: "btn-eco-petrol-2",
          onClick: () => {
            this.closeModalTerminos(true);
            PubSub.publish(layoutConsts.CLOSE_MODAL);
          }
        },
        {
          title: "Cancelar",
          color: "secondary",
          className: "btn-eco-petrol-2",
          onClick: () => {
            this.closeModalTerminos(false);
            PubSub.publish(layoutConsts.CLOSE_MODAL);
          }
        }
      ]
    });
    this.setState({
      showTerminos: true
    });
    this.resetForm();
  }
  async closeModalPolitica(accept) {
    this.setState({
      showPoliticas: false
    });
    if (accept) {
      //var json = JSON.parse(atob(localStorage.getItem("user-data")));
      showLoading();
      await callServicesLogin(
        OP_CONST.PUT,
        "usuario/habeas/" + this.state.user.id,
        ""
      );
      closeLoading();
      this.validateLogin();
    } else {
      this.Validator.reset("login-doctype");
      this.Validator.reset("login-docnumber");

      this.setState({
        doctype: "",
        docnumber: ""
      });
    }
  }
  openModalSimple(modal) {
    if (modal == "email") {
      this.setState({
        emailNotFound: true
      });
    }
  }
  closeModalSimple(modal) {
    switch (modal) {
      case "user":
        this.Validator.reset("login-doctype");
        this.Validator.reset("login-docnumber");

        this.setState({
          userNotFound: true,
          doctype: "",
          docnumber: ""
        });
        break;
      case "email":
        this.Validator.reset("login-doctype");
        this.Validator.reset("login-docnumber");

        this.setState({
          emailNotFound: false,
          doctype: "",
          docnumber: ""
        });
    }
  }

   directorio(){
    document.location.href = '/directory';
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col lg={2} md={1} sm={2} xs={12} />
            <Col lg={8} md={10} sm={8} xs={12}>
              <Row className="login-page">
                <Col md={6} xs={12} className="no-padding">
                  <Card className="card-user login-content">
                    <CardHeader className="login-header">
                      <div className="logo">
                        <img src={assets.logo.src} />
                      </div>
                      <CardTitle
                        className="title color-palette-green text-bold"
                        align="center"
                      >
                        BIENVENIDO
                      </CardTitle>
                      <CardTitle
                        className="subtitle login-padding-text"
                        align="center"
                      >
                        Ingrese sus datos personales
                      </CardTitle>
                    </CardHeader>
                    <CardBody className="login-padding">
                      <form>
                        <FormGroup>
                          <Input
                            type="select"
                            name="doctype"
                            id="doctype"
                            className="test-doctype login-doctype"
                            value={this.state.doctype}
                            onChange={this.handleChangeDocType}
                          >
                            <option value="" disabled>
                              Seleccione tipo de documento
                            </option>
                            <option value="CC">Cédula de Ciudadanía</option>
                            <option value="CE">Cédula de Extranjería</option>
                            <option value="PA">Pasaporte</option>
                          </Input>
                          {!this.Validator.validate(
                            "login-doctype",
                            this.isValidDocumentType
                          ) && (
                            <FormMessage
                              value="Por favor seleccione el tipo de documento"
                              className="error"
                            />
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Input
                            type="text"
                            name="docnumber"
                            id="docnumber"
                            maxLength="13"
                            placeholder="Número de identificación"
                            className="test-docnumber login-docnumber"
                            value={this.state.docnumber}
                            onChange={this.handleChangeDoc}
                          />
                          {!this.Validator.validate(
                            "login-docnumber",
                            this.isValidDocument
                          ) && (
                            <FormMessage
                              value="Por favor valide el número de documento"
                              className="error"
                            />
                          )}
                        </FormGroup>
                        <Row>
                          <ReCAPTCHA
                            ref={el => {
                              this.captchaDemo = el;
                            }}
                            size="invisible"
                            render="explicit"
                            sitekey={CONST.RECAPTCHA_SITE_KEY}
                            onChange={this.verifyCallback}
                            //badge="inline"
                          />
                        </Row>

                        <Row className="login-buttons">
                          <div className="update ml-auto mr-auto">
                            <Button
                              data-testid="login-button"
                              className={[
                                "test-btnlogin",
                                !this.disabledButton()
                                  ? "btn-ecopetrol"
                                  : "btn-disabled"
                              ].join(" ")}
                              disabled={this.disabledButton()}
                              onClick={this.validateLogin}
                              round="true"
                            >
                              CONTINUAR
                            </Button>
                            <Button
                              className="login-buttons-redmed"
                              data-testid="login-button-redmed"
                              onClick={this.directorio}>
                                RED DE PRESTADORES
                            </Button>     
                            <ReCaptchaTerms />
                          </div>
                        </Row>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
                <Col
                  md={6}
                  style={{ backgroundImage: "url(" + image1 + ")" }}
                  className="login-background"
                />
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default connectComponent(Login);
