/**
 * Modulo que contiene las urls necesarias para la comunicación con el servidor
 */
 module.exports = {
  BASE_URL: "https://api.ecopetrol.com.co/esalud-directorio/api/",
  BASE_PHY:"https://api.ecopetrol.com.co/esalud-directorio/api/directorio",
  BASE_LOGIN_URL: "https://api.ecopetrol.com.co/esalud-login/api/",
  BASE_ADMIN_URL: "https://api.ecopetrol.com.co/hsesecurity/api/v2/",
  BASE_HABEAS: "https://api.ecopetrol.com.co/hsesecurity/api/v2/habeasdata",
  BASE_CMS: "https://api.ecopetrol.com.co/esalud-cms/api/",
  API_KEY: "30a1c91f2e8544c49ab7037b4883004b",
//**HEON */
  BASE_HEON_URL: "https://api.ecopetrol.com.co/esalud-heon/api/",
  DOCTOR_URL:
    "https://api.ecopetrol.com.co/esalud-heon/megaOgaPrestadorEspecialidad/api/",
  CHANGE_URL: "https://api.ecopetrol.com.co/esalud-heon/actualizarMegaOga/api/",
  APPOINTMENT_URL: "https://api.ecopetrol.com.co/esalud-heon/api/Citas/",
  ASIGN_APPOINTMENT_URL: "https://api.ecopetrol.com.co/esalud-heon/api/Citas/",
  HISTORY_URL: "https://api.ecopetrol.com.co/esalud-heon/api/",
  API_KEY_HEON:"3116F5C0-488C-4F85-ACC6-2830EFDEE738",
  //ID_PROCESS_MEFA --->127616
  ID_PROCESS_MEGA:127554 ,//2307
  ID_PROCESS_OGA: 127556,//2151
  ID_PROCESS_MEGA_CHILDREN:127554 , //2307,//127573
  ID_PROCESS_OGA_CHILDREN:127556, //2151,//127636
  RECAPTCHA_SITE_KEY: "6Lf47pMfAAAAAABSNc0_L2HgWXT5Wo_2Mc-Z3Fj0",
  REDIRECT_URL_AUTH:"https://as-aeu-ecp-prd-esalud.azurewebsites.net/blank.html",
  CLIENTID_AUTH:"1145f54a-1850-494b-9971-62486709a3de"
};