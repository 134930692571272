import moment from "moment";
import PubSub from "pubsub-js";
import React, { Component } from "react";
import { RenderCustomModal } from "../utils/Login.modal";
import ErrorMessage from "./ErrorMessage";

export const layoutConsts = {
  SHOW_MODAL: "LAYOUT_SHOW_MODAL",
  CLOSE_MODAL: "LAYOUT_CLOSE_MODAL",
  UPDATE_MODAL: "LAYOUT_UPDATE_MODAL"
};

export default class ModalLayout extends Component {
  constructor(props) {
    super(props);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateModal = this.updateModal.bind(this);

    this.state = {
      date: null,
      modalParams: []
    };
  }
  showModal(event, params) {
    let modalParams = [...this.state.modalParams, params];

    this.setState({ modalParams: modalParams });
  }
  closeModal() {
    let modalParams = [...this.state.modalParams];
    modalParams.splice(-1, 1);

    this.setState({ modalParams: modalParams });
  }
  updateModal() {
    this.setState({ date: moment().millisecond() });
  }

  componentDidMount() {
    this.subscribeModal = PubSub.subscribe(
      layoutConsts.SHOW_MODAL,
      this.showModal
    );
    this.subscribeCloseModal = PubSub.subscribe(
      layoutConsts.CLOSE_MODAL,
      this.closeModal
    );
    this.subscribeUpdateModal = PubSub.subscribe(
      layoutConsts.UPDATE_MODAL,
      this.updateModal
    );
  }
  componentWillUnmount() {
    PubSub.unsubscribe(this.subscribeModal);
    PubSub.unsubscribe(this.subscribeCloseModal);
    PubSub.unsubscribe(this.subscribeUpdateModal);
  }
  renderModal() {
    return (
      !!this.state.modalParams &&
      this.state.modalParams.map((params, index) => {
        let className =
          index < this.state.modalParams.length - 1
            ? " modal-hide-content"
            : "";
        return (
          <RenderCustomModal
            key={index.toString()}
            {...params}
            className={params.className + className}
            close={this.closeModal}
          />
        );
      })
    );
  }

  render() {
    return (
      <div>
        <ErrorMessage
          error={this.props.auth ? this.props.auth.error : null}
          updateAuth={this.props.updateAuth}
        />

        {this.props.children}
        {this.renderModal()}
      </div>
    );
  }
}
