export const UPDATE_AUTH = 'UPDATE_AUTH'
export const CLOSE_SESSION = 'CLOSE_SESSION'
export function updateAuth(data){
    return {
        type: UPDATE_AUTH,
        data
    }
}
export function closeSession(){
    return {
        type: CLOSE_SESSION,
    }
}