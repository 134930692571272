import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
//import Modal from 'react-awesome-modal';
import { resizeObserverConst } from "./resizeObserverConst";
import PubSub from "pubsub-js";
import CalculatedIcon from "../components/CalculatedIcon";
import { StyledCustomModalHeader } from "../components/styledComponents";
import styled from "styled-components";

const LoadingModal = props => (
  <CustomModal {...props} className={props.className}>
    {props.children}
  </CustomModal>
);
export const StyledLoadingModal = styled(LoadingModal)`
  .modal-content {
    background-color: transparent !important;
    border: none !important;
  }
`;

export class CustomModal extends Component {
  constructor() {
    super();
  }
  renderComponent() {
    return this.props.component;
  }
  renderDefaultModal() {
    return (
      <>
        <div>
          {!!this.props.icon && (
            <CalculatedIcon alt="Thumbnail" size={65} {...this.props.icon} />
          )}
          {!!this.props.icon && <br />}
          {this.props.title && this.props.title != "" && (
            <h5>{this.props.title}</h5>
          )}
          {this.props.message && this.props.message != "" && (
            <div
              dangerouslySetInnerHTML={{ __html: this.props.message }}
              className="message"
            />
          )}
        </div>
        {this.props.component && this.props.component()}
      </>
    );
  }

  render() {
    const body = this.renderDefaultModal();
    const {
      visible,
      close,
      closemodal,
      buttons,
      title,
      component,
      showHeader,
      ...additionalProps
    } = this.props;
    if (!visible) {
      return null;
    }
    const calculatedShowHeader =
      typeof showHeader === "undefined" || showHeader;
    return (
      <Modal
        {...additionalProps}
        isOpen={visible}
        toggle={closemodal}
        className={this.props.className}
      >
        {calculatedShowHeader && <StyledCustomModalHeader toggle={close} />}
        <ModalBody className={this.props.classBody}>{body}</ModalBody>
        <ModalFooter>
          <div className="row">
            {!!buttons &&
              buttons.map((button, index) => (
                <div key={index.toString()} className="col-xs-12">
                  <Button
                    color={!!button.color ? button.color : "primary"}
                    className={
                      "login-btn " +
                      (!!button.className ? button.className : "")
                    }
                    href="javascript:void(0);"
                    round="true"
                    onClick={button.onClick}
                  >
                    {button.title}
                  </Button>
                </div>
              ))}
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export class ModalComponent extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.subscribeToken = PubSub.subscribe(
      resizeObserverConst.TYPE_EVENT.RESIZE,
      this.responsiveResize
    );
  }
  componentWillUnmount() {
    PubSub.unsubscribe(this.subscribeToken);
  }
  responsiveResize(msg, data) {}

  render() {
    let props = { ...this.props };
    delete props.title;
    return (
      <Modal
        {...props}
        backdrop={true}
        isOpen={this.props.visible}
        toggle={this.props.close}
        className={this.props.className}
      >
        <StyledCustomModalHeader
          className={this.props.headerClassName}
          toggle={this.props.close}
        >
          {this.props.title}
        </StyledCustomModalHeader>
        <ModalBody className={this.props.classBody}>
          {this.props.children}
        </ModalBody>
        {!this.props.hideButtons && (
          <ModalFooter className="center-text">
            <Button
              href="javascript:void(0);"
              className="btn-eco-petrol-2"
              round="true"
              onClick={this.props.closeYes}
            >
              Aceptar
            </Button>{" "}
            <Button
              href="javascript:void(0);"
              className="btn-disabled"
              round="true"
              onClick={this.props.closeNo}
            >
              Cancelar
            </Button>
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

export const RenderCustomModal = props => {
  const className =
    "login-message " + (!!props.className ? props.className : "");
  const buttons = !!props.buttons
    ? props.buttons.map(button => {
        return {
          ...button,
          onClick: !!button.onClick ? button.onClick : props.close
        };
      })
    : [];
  const { isLoading, ...additionalProps } = props;
  const ModalComponent = !!props.isLoading ? StyledLoadingModal : CustomModal;
  return (
    <ModalComponent
      {...additionalProps}
      visible={!!props}
      className={className}
      buttons={buttons}
    />
  );
};
