import React from "react";
import Loading from "./Loading";
import { Button } from "reactstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEdit,
  faMinusCircle,
  faPlus,
  faPlusCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showModal } from "../utils/showModal";
library.add(faEdit);
library.add(faPlus);
library.add(faMinusCircle);
library.add(faPlusCircle);
const DetailItem = props => {
  if (!props.item.visible) {
    return null;
  }
  return (
    <div className="card flex-row border-0 detail-item">
      <img
        src={props.item.image.src}
        className="img-icon"
        alt={props.item.image.alt}
      />
      <div>
        <p className="card-text title">{props.item.title}</p>
        <p className="card-text value">{props.item.value}</p>
      </div>
    </div>
  );
};

export const DetailItems = props => (
  <ul className="list-group list-group-flush mega-detail color-palette-green">
    <li className="list-group-item">
      {props.detailItems.map((item, index) => (
        <DetailItem item={item} key={index.toString()} />
      ))}
    </li>
  </ul>
);

export function LoadingFunc() {
  return <Loading width="60" height="60" className="mx-auto" />;
}

export const DefaultAdminDetailItem = props => (
  <div className="card-text mb-auto">
    <b>{props.item.title}:</b> {props.item.value}
  </div>
);
export const StateAdminDetailItem = props => (
  <div className="card-text mb-auto">
    <b>Estado:</b> {props.item.state ? "Activo" : "Inactivo"}
  </div>
);

export const AdminDetail = props => (
  <div className="card flex-row box-shadow border-0 WorkForce-item-card">
    <div className="card-body">
      {props.detailItems.map((item, index) => {
        const Component = item.component
          ? item.component
          : DefaultAdminDetailItem;
        return (
          <div key={index.toString()}>
            <Component item={item} />
          </div>
        );
      })}
      <Button
        color="primary"
        className="btn-eco-petrol-2 medium"
        href="javascript:void(0);"
        round="true"
        onClick={props.onClick}
      >
        <FontAwesomeIcon className="action-icon" icon="edit" />
        Editar
      </Button>
      <Button
        color="primary"
        className="btn-eco-petrol-2 medium"
        href="javascript:void(0);"
        round="true"
        onClick={props.onClickSecondButton}
      >
        <FontAwesomeIcon
          className="action-icon"
          icon={props.secondButtonIcon(props.row)}
        />
        {props.secondButtonName(props.row)}
      </Button>
    </div>
  </div>
);

export const AdminDetail2 = props => (
  <div className="card flex-row box-shadow border-0 WorkForce-item-card">
    <div className="card-body">
      {props.detailItems.map((item, index) => {
        const Component = item.component
          ? item.component
          : DefaultAdminDetailItem;
        return (
          <div key={index.toString()}>
            <Component item={item} />
          </div>
        );
      })}
      <Button
        color="primary"
        className="btn-eco-petrol-2 medium"
        href="javascript:void(0);"
        round="true"
        onClick={props.onClick}
      >
        <FontAwesomeIcon className="action-icon" icon="edit" />
        Editar
      </Button>
    </div>
  </div>
);

export const ChangeStatusAdminDetail = props => (
  <AdminDetail
    row={props.row}
    secondButtonName={row => (props.row.state ? "Inactivar" : "Activar")}
    secondButtonIcon={row => (props.row.state ? "minus-circle" : "plus-circle")}
    detailItems={props.detailItems}
    onClick={e => {
      props.component.editRow(props.row, e);
      showModal(
        "",
        "Editar",
        null,
        [],
        () => props.component.renderModalComponent(props.row),
        {
          className: "large-modal"
        }
      );
    }}
    onClickSecondButton={e => {
      props.component.changeRowStatus(props.row);
    }}
  />
);

export const ChangeStatusAdminDetail_2 = props => (
  <AdminDetail2
    row={props.row}
    secondButtonName={row => (props.row.state ? "Inactivar" : "Activar")}
    secondButtonIcon={row => (props.row.state ? "minus-circle" : "plus-circle")}
    detailItems={props.detailItems}
    onClick={e => {
      props.component.editRow(props.row, e);
      showModal(
        "",
        "Editar",
        null,
        [],
        () => props.component.renderModalComponent(props.row),
        {
          className: "large-modal"
        }
      );
    }}
    onClickSecondButton={e => {
      props.component.changeRowStatus(props.row);
    }}
  />
);


