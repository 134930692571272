import CONST from "./Constants";
export const DOCUMENT_KEY = {
  CC: 2,
  CE: 3,
  PA: 6
};

export const DOCTOR_ID = {
  ADULT: {
    MEGA: 1,
    OGA: 3
  },
  CHILDREN: {
    MEGA: 2,
    OGA: 4
  },
  MEGA: {
    ADULT: 1,
    CHILDREN: 2
  },
  OGA: {
    ADULT: 3,
    CHILDREN: 4
  }
};
export const DOCTOR_ID_IS_MEGA = id => {
  return [1, 2].indexOf(id) !== -1;
};
export const CALCULATE_DOCTOR_ID = (type, isAdult) => {
  if (!!isAdult) {
    return DOCTOR_ID.ADULT[type];
  } else {
    return DOCTOR_ID.CHILDREN[type];
  }
};
export const DOCTOR_NAME = {
  "1": "MEGA",
  "2": "MEGA",
  "3": "OGA",
  "4": "OGA"
};
export const ASSIGN_APPOINTMENT_PROCESS_ID = {
  MEGA: {
    ADULT: CONST.ID_PROCESS_MEGA,
    CHILDREN: CONST.ID_PROCESS_MEGA_CHILDREN
  },
  OGA: {
    ADULT: CONST.ID_PROCESS_OGA,
    CHILDREN: CONST.ID_PROCESS_OGA_CHILDREN
  }
};
export const VARIABLES = {
  // Horas de plazo para cancelar la cita
  CANCEL_APPOINTMENT_VALID_TERM: 2, // Horas antes de la cita para poderla cancelar
  ASSING_APPOINTMENT_EXPIRED_DEADLINE: 1, // Horas antes de la cita para no poderla solicitar
  SHOW_CHANGE_MEGAOGA: false
};

export const AdminLogin = [
  {
    type: "row",
    fields: [
      {
        id: "user",
        type: "text",
        className: "col-sm-12",
        fieldClassName: "loginInputFields",
        title: "",
        placeholder: "Ingrese el usuario"
      },
      {
        id: "pass",
        type: "password",
        className: "col-sm-12",
        fieldClassName: "loginInputFields",
        title: "",
        placeholder: "Ingrese la contraseña"
      }
    ]
  }
];

export const salaryTypes = [
  {
    label: "Directivo",
    value: 1
  },
  {
    label: "Convencional",
    value: 2
  }
];

export const NOTIFICATION_TYPE = {
  ASI: "ASI",
  CAN: "CAN",
  P24: "P24"
};

export const idTypes = [
  {
    label: "Cédula de ciudadanía",
    value: "CC"
  },
  {
    label: "Cédula de extranjería",
    value: "CE"
  },
  {
    label: "Desconocido",
    value: "Desconocido"
  },
  {
    label: "Pasaporte",
    value: "PS"
  },
  {
    label: "Registro civil",
    value: "Registro Civil"
  },
  {
    label: "Tarjeta de identidad",
    value: "Tarjeta de Identidad"
  },

  {
    label: "Visa",
    value: "Visa"
  }
];
