import { CLOSE_SESSION, UPDATE_AUTH } from "../actions/auth";
import initialState from "./initialState";

export default function auth(state = initialState.auth, action = undefined) {
  let data, auth;
  switch (action.type) {
    case UPDATE_AUTH:
      auth = Object.assign({}, state.auth, action.data.auth);
      var temporal = Object.assign({}, state, action.data, {
        auth,
        offlineLoaded: true
      });
      return temporal;

    case CLOSE_SESSION:
      data = Object.assign(
        {},
        state,
        {
          auth: {
            user: null,
            token: null,
            beneficiarios: null
          }
        },
        {
          authenticated: false
        }
      );
      return data;
    default:
      return state;
  }
}
