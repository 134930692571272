import { Component } from "react";
// Helper para redirigir a una ruta ya que programáticamente desde el objeto history no funciona
// y react router no exporta dicho objeto.

export class Redirect extends Component {
  constructor(props) {
    super(props);
  }
  async manageRedirect() {
    if (typeof this.props.redirectTo !== "undefined" && this.props.redirectTo) {
      const redirectTo = this.props.redirectTo;
      await this.props.updateAuth({
        redirectTo: null
      });
      await this.props.history.push(redirectTo);
    }
  }

  render() {
    this.manageRedirect();
    return null;
  }
}

export default Redirect;
