import React from "react";
import { ModalHeader } from "reactstrap";
import styled from "styled-components";

const HasBoostrapTable = ({ className, children }) => (
  <div className={className}>{children}</div>
);
export const StyledHasBoostrapTable = styled(HasBoostrapTable)`
  table {
    border-collapse: separate !important;
  }
`;

const CustomModalHeader = props => (
  <ModalHeader {...props} className={props.className}>
    {props.children}
  </ModalHeader>
);
export const StyledCustomModalHeader = styled(CustomModalHeader)`
  button.close {
    background-color: transparent !important;
  }
`;

const HasBreadcrumb = ({ className, children }) => (
  <div className={className}>{children}</div>
);
export const StyledHasBreadcrumb = styled(HasBreadcrumb)`
  .breadcrumb {
    background-color: transparent !important;
  }
`;

const HasDatePicker = ({ className, children }) => (
  <div className={className}>{children}</div>
);
export const StyledHasDatePicker = styled(HasDatePicker)`
  .react-datepicker__close-icon {
    background-color: transparent;
    top: 15%;
  }
`;
