import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PubSub from "pubsub-js";
import React from "react";
import Sidebar from "react-sidebar";
import { assets } from "../assets";
import BreadCrumbView from "../utils/breadcrumb";
import { connectComponent } from "../utils/connect";
import { renderEventConst } from "../utils/renderEvent";
import CustomSideBar from "./CustomSideBar";
import ErrorMessage from "./ErrorMessage";
import ModalLayout from "./ModalLayout";
import Redirect from "./Redirect";
import { sidebarConsts } from "./SideBar";
import User from "./User";

const mql = window.matchMedia(`(min-width: 768px)`);
library.add(faBars);

export const NavBar = props => (
  <div className="sidebar-sticky">
    <div className="d-none d-md-block sidebar-logo p-1">
      <img src={assets.logo.src} />
    </div>
    <CustomSideBar {...props} />
  </div>
);

export const LgHeader = props => (
  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
    <div />
    <div className="btn-toolbar mb-2 mb-md-0">
      <div className="btn-group mr-2">
        <User user={props.auth.auth.user} />
      </div>
    </div>
  </div>
);
export const SmHeader = props => (
  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
    <a
      href=""
      className="hamburguer"
      onClick={e => props.onSetSidebarOpen(true, e)}
    >
      <FontAwesomeIcon className="hamburguer-icon" icon="bars" />
    </a>

    <div className="header-logo p-1 mx-auto">
      <img src={assets.logo.src} />
    </div>
  </div>
);
export const LayoutContent = props => (
  <div className="principal-container container-fluid p-0 animated fadeIn">
    <div className="row">
      <main role="main" className="col-md-12 ml-sm-auto pt-3 px-4">
        {props.header}
        {props.breadcrumb}
        <div className="principal-title row">
          <h1 className="h2 col">{props.title}</h1>
        </div>

        <div className="principal-content">{props.children}</div>
      </main>
    </div>
  </div>
);

export const Footer = props => (
  <footer className={"page-footer font-small " + props.className}>
    <div className="footer-copyright text-center py-3">
      © 2018 Ecopetrol. Todos los derechos reservados. Línea de atención
      01-8000-915556
    </div>
  </footer>
);

class Layout extends ModalLayout {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      sidebarDocked: mql.matches,
      sidebarOpen: false,
      footerSticky: true
    };
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.closeSideBar = this.closeSideBar.bind(this);
  }

  componentWillMount() {
    super.componentWillUnmount();
    mql.addListener(this.mediaQueryChanged);

    window.addEventListener("scroll", this.handleScroll, true);
  }
  componentDidMount() {
    super.componentDidMount();
    this.handleScroll();
    this.subscribeToken = PubSub.subscribe(
      renderEventConst.ON_RENDER,
      this.handleScroll
    );
    this.subscribeCloseSideBar = PubSub.subscribe(
      sidebarConsts.CLOSE,
      this.closeSideBar
    );
  }
  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
    window.removeEventListener("scroll", this.handleScroll);
    PubSub.unsubscribe(this.subscribeToken);
    PubSub.unsubscribe(this.subscribeCloseSideBar);
  }
  handleScroll(event) {
    var contentSidebar = document.getElementById("content-sidebar-id");
    if (!contentSidebar) {
      return;
    }

    if (
      contentSidebar.scrollHeight > contentSidebar.clientHeight &&
      this.state.footerSticky
    ) {
      this.setState({
        footerSticky: false
      });
    } else if (
      contentSidebar.scrollHeight <= contentSidebar.clientHeight &&
      !this.state.footerSticky
    ) {
      this.setState({
        footerSticky: true
      });
    }
  }
  closeSideBar() {
    this.setState({ sidebarOpen: false });
  }
  onSetSidebarOpen(open, e) {
    if (typeof e !== "undefined") {
      e.preventDefault();
    }

    this.setState({ sidebarOpen: open });
  }
  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  render() {
    const { pathname } = this.props.location;

    const breadcrumb = (
      <BreadCrumbView route={pathname} history={this.props.history} />
    );

    const Header = this.state.sidebarDocked ? (
      <LgHeader {...this.props} />
    ) : (
      <SmHeader onSetSidebarOpen={this.onSetSidebarOpen} {...this.props} />
    );
    const footerClassName = this.state.footerSticky ? "sticky" : "";
    return (
      <Sidebar
        sidebar={<NavBar {...this.props} />}
        open={this.state.sidebarOpen}
        docked={this.state.sidebarDocked}
        onSetOpen={this.onSetSidebarOpen}
        styles={{ sidebar: { background: "white" } }}
        transitions={false}
        touch={false}
        contentId="content-sidebar-id"
        //touchHandleWidth={10}
      >
        <Redirect
          history={this.props.history}
          redirectTo={this.props.auth.redirectTo}
          updateAuth={this.props.updateAuth}
        />
        <ErrorMessage
          error={this.props.auth.error}
          updateAuth={this.props.updateAuth}
        />
        <LayoutContent
          {...this.props}
          header={Header}
          breadcrumb={breadcrumb}
        />
        <Footer className={footerClassName} />
        {this.renderModal()}
      </Sidebar>
    );
  }
}

export default connectComponent(Layout);
