export const resizeObserverConst = {
  TYPE_EVENT: {
    RESIZE: 'RESIZE_OBSERVER_RESIZE',
    RESPONSIVE_RESIZE: 'RESIZE_OBSERVER_RESPONSIVE_RESIZE',
  },
  EVENT: {
    xs: 'RESIZE_OBSERVER_XS',
    sm: 'RESIZE_OBSERVER_SM',
    md: 'RESIZE_OBSERVER_MD',
    lg: 'RESIZE_OBSERVER_LG',
    xl: 'RESIZE_OBSERVER_XL',
  }
}
