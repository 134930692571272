import { callServicesLogin } from "./Services";
import OP_CONST from "./OperationConstants";
import LOGIN_CONST from "./LoginConstants";
import moment from "moment";

const requiredAttributes = LOGIN_CONST.requiredAttributes;
class AuthServiceClass {
  loginAdmin = false;
  isAdmin = false;
  async callAuthServicesLogin(doctype, docnumber) {
    var login = await callServicesLogin(
      OP_CONST.GET,
      "usuario?TipoDoc=" + doctype + "&Doc=" + docnumber,
      ""
    );
    if (login.data.usuario != null) {
      const attributesPending = requiredAttributes.filter(
        permision =>
          typeof login.data.usuario[permision] !== "undefined" &&
          !login.data.usuario[permision]
      );
      let beneficiarios = {};
      if (login.data.beneficiarios && login.data.beneficiarios.length > 0) {
        for (let index = 0; index < login.data.beneficiarios.length; index++) {
          const beneficiario = login.data.beneficiarios[index];
          beneficiarios[beneficiario.doc] = {
            fechaFinVigencia: moment(beneficiario.fechaFinVigencia).format(
              "DD/MM/YYYY"
            )
          };
        }
      }
      return {
        attributesPending,
        user: login.data.usuario,
        beneficiarios: beneficiarios
      };
    }
    return {
      attributesPending: requiredAttributes,
      user: null,
      beneficiarios: null
    };
  }
}
let AuthService = new AuthServiceClass();
export default AuthService;
