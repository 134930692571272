import React, { Component } from "react";
import moment from "moment";
import "moment/locale/es-do";
import { assets } from "../assets";
import styled from "styled-components";

const UserBox = ({ className, children }) => (
  <div className={"card flex-row box-shadow border-0 user-card " + className}>
    {children}
  </div>
);
const StyledUserBox = styled(UserBox)`
  background-color: transparent !important;
`;

export default class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetail: false,
      detailItems: null
    };

    this.onClick = this.onClick.bind(this);
  }
  onClick(e) {
    e.preventDefault();
    this.setState({
      showDetail: !this.state.showDetail
    });
    return false;
  }

  render() {
    const vigency =
      this.props.user && this.props.user.FinVigencia
        ? moment(this.props.user.FinVigencia).format("l") +
          " Vigencia reportada"
        : "";
    return (
      <StyledUserBox>
        <img
          className="float-left rounded-circle user-image"
          alt="Thumbnail [200x250]"
          src={assets.personMedium.src}
        />
        <div className="card-body">
          <h3 className="mb-0 user-title color-palette-green">
            <span className="bienvenido">Bienvenido</span> <br />
            {this.props.user
              ? !!this.props.user.name
                ? this.props.user.name + " " + this.props.user.lastName
                : this.props.user.nombres + " " + this.props.user.apellidos
              : ""}
          </h3>
          <p className="card-text mb-auto mega-subtitle">
            {this.props.user ? vigency : ""}
          </p>
        </div>
      </StyledUserBox>
    );
  }
}
