import PubSub from "pubsub-js";
import React from "react";
import { assets } from "../assets";
import Loading from "../components/Loading";
import { layoutConsts } from "../components/ModalLayout";
import TEXTS from "./TextContent";

export function showModal(
  message,
  messageTitle,
  messageIcon,
  buttons,
  component,
  otherParams
) {
  buttons = !!buttons
    ? buttons
    : [
        {
          title: "Aceptar",
          className: "btn-eco-petrol-2"
        }
      ];
  PubSub.publish(layoutConsts.SHOW_MODAL, {
    ...otherParams,
    title: messageTitle,
    message: message,
    icon: messageIcon,
    buttons,
    component
  });
}

export function showModalAcceptCancel(
  message,
  messageTitle,
  messageIcon,
  accept,
  component,
  cancel,
  otherParams
) {
  showModal(
    message,
    messageTitle,
    messageIcon,
    [
      {
        title: "Aceptar",
        color: "primary",
        className: "btn-eco-petrol-2",
        onClick: () => {
          PubSub.publish(layoutConsts.CLOSE_MODAL);
          accept();
        }
      },
      {
        title: "Cancelar",
        color: "secondary",
        className: "btn-eco-petrol-2"
      }
    ],
    component
  );
}

export function showModalErrorHeon() {
  showModal(TEXTS.ERROR_HEON, "LO SENTIMOS", assets.notFound);
}
export function showModalDisableServices() {
  showModal(TEXTS.ERROR_DISABLE_SERVICES, "LO SENTIMOS", assets.notFound);
}

export function showModalErrorMegaOga() {
  showModal(TEXTS.ERROR_MEGAOGA, "ASIGNACIÓN", assets.telefonoVerde);
}
export function showModalNetworkingError() {
  showModal(TEXTS.ERROR_NETWORK, "LO SENTIMOS", assets.wifiVerde);
}
export function showModalError500() {
  showModal(TEXTS.ERROR_500, "LO SENTIMOS", assets.wifiVerde);
}

export function showLoading() {
  showModal(
    "",
    "",
    "",
    [],
    () => <Loading width="60" height="60" className="mx-auto" />,
    {
      wrapClassName: "loading-container",
      className: "modal-dialog-centered",
      isLoading: true,
      showHeader: false
    }
  );

  return;
}
export function closeLoading() {
  PubSub.publish(layoutConsts.CLOSE_MODAL);
}
export function closeModal() {
  PubSub.publish(layoutConsts.CLOSE_MODAL);
}
