import PropTypes from "prop-types";
import React from "react";
import Loadable from "react-loadable";
import { Provider } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { LoadingFunc } from "../components/CustomComponents";
import Layout from "../components/Layout";
import ModalLayout from "../components/ModalLayout";
import AuthService from "../services/AuthService";
import { configureAxios } from "../utils/configureAxios";
import { connectComponent } from "../utils/connect";
import resizeObserver from "../utils/resizeObserver";
import Codigo from "../views/Pages/Codigo/Codigo";
import Login from "../views/Pages/Login/Login";

const AdminLogin = Loadable({
  loader: () => import("../views/Pages/AdminLogin/AdminLogin"),
  loading: LoadingFunc
});
const Dashboard = Loadable({
  loader: () => import("../views/Dashboard/Dashboard"),
  loading: LoadingFunc
});
const Directory = Loadable({
  loader: () => import("../views/Directory/Directory"),
  loading: LoadingFunc
});
const ChangeDoctor = Loadable({
  loader: () => import("../views/ChangeDoctor/ChangeDoctor"),
  loading: LoadingFunc
});
const AppointmentDashboard = Loadable({
  loader: () => import("../views/AppointmentDashboard/AppointmentDashboard"),
  loading: LoadingFunc
});
const AssingAppointment = Loadable({
  loader: () => import("../views/AssingAppointment/AssingAppointment"),
  loading: LoadingFunc
});
const AppointmentHistory = Loadable({
  loader: () => import("../views/AppointmentHistory/AppointmentHistory"),
  loading: LoadingFunc
});

const Magazine = Loadable({
  loader: () => import("../views/Magazine/Magazine"),
  loading: LoadingFunc
});
const FAQView = Loadable({
  loader: () => import("../views/FAQView/FAQView"),
  loading: LoadingFunc
});

const AdminFAQ = Loadable({
  loader: () => import("../views/admin/AdminFAQ/AdminFAQ"),
  loading: LoadingFunc
});
const AdminUser = Loadable({
  loader: () => import("../views/admin/AdminUser/AdminUser"),
  loading: LoadingFunc
});
const AdminMagazine = Loadable({
  loader: () => import("../views/admin/AdminMagazine/AdminMagazine"),
  loading: LoadingFunc
});
const AdminDashboard = Loadable({
  loader: () => import("../views/admin/AdminDashboard/AdminDashboard"),
  loading: LoadingFunc
});
const AdminNotification = Loadable({
  loader: () => import("../views/admin/AdminNotification/AdminNotification"),
  loading: LoadingFunc
});
const AdminSendEmail = Loadable({
  loader: () => import("../views/admin/AdminSendEmail/AdminSendEmail"),
  loading: LoadingFunc
});
const AdminPhysician = Loadable({
  loader: () => import("../views/admin/AdminPhysician/AdminPhysician"),
  loading: LoadingFunc
});

/*
  Route wrapper
 */

const LoginRoute = ({ component: Component, ...rest }) => {
  const ConnectedModalLayout = connectComponent(ModalLayout);
  return (
    <Route
      {...rest}
      render={matchProps => (
        <ConnectedModalLayout {...matchProps}>
          <Component {...matchProps} />
        </ConnectedModalLayout>
      )}
    />
  );
};

const DashboardRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout {...matchProps} title={rest.title}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

const AssingAppointmentRoutes = () => (
  <Switch>
    <DashboardRoute
      exact
      path="/appointmentdashboard"
      component={AppointmentDashboard}
      title="Mis citas"
    />
    <DashboardRoute
      path="/appointmentdashboard/assingappointment"
      component={AssingAppointment}
      title="Asignación de cita"
    />
    <DashboardRoute
      path="/appointmentdashboard/appointmenthistory"
      component={AppointmentHistory}
      title="Histórico de citas"
    />
  </Switch>
);

const AdminRoutes = () => (
  <Switch>
    <DashboardRoute
      exact
      path="/admin"
      component={AdminDashboard}
      title="Administración"
    />
    <DashboardRoute
      exact
      path="/admin/user"
      component={AdminUser}
      title="Administrar usuarios"
    />
    <DashboardRoute
      exact
      path="/admin/faq"
      component={AdminFAQ}
      title="Administrar preguntas frecuentes"
    />
    <DashboardRoute
      exact
      path="/admin/magazine"
      component={AdminMagazine}
      title="Administrar revistas"
    />
    <DashboardRoute
      exact
      path="/admin/notification"
      component={AdminNotification}
      title="Administrar notificaciones"
    />
    <DashboardRoute
      exact
      path="/admin/sendEmail"
      component={AdminSendEmail}
      title="Administrar correos"
    />
    <DashboardRoute
      exact
      path="/admin/physician"
      component={AdminPhysician}
      title="Administrar red de prestadores"
    />
  </Switch>
);
const UserProvider = props => (
  <Provider store={props.store}>
    {true && (
      <Route
        path="/"
        render={() =>
          props.isAuthenticated ? (
            <>
              <Route
                exact={true}
                path="/"
                render={() => <Redirect to="/dashboard" />}
              />
              <Route
                exact={true}
                path="/login"
                render={() => <Redirect to="/dashboard" />}
              />
            </>
          ) : props.isLogin ? null : (
            <Redirect to="/login" />
          )
        }
      />
    )}

    <LoginRoute path="/login" component={Login} />
    <LoginRoute path="/codigo" component={Codigo} />
    <DashboardRoute path="/dashboard" component={Dashboard} />
    <DashboardRoute
      path="/directory"
      component={Directory}
      title="Red de prestadores"
    />
    <DashboardRoute
      path="/changedoctor"
      component={ChangeDoctor}
      title="Elegir médico"
    />
    <Route path="/appointmentdashboard" component={AssingAppointmentRoutes} />
    <DashboardRoute
      path="/magazine"
      component={Magazine}
      title="Revista digital"
    />
    <DashboardRoute
      path="/faqview"
      component={FAQView}
      title="Preguntas frecuentes"
    />
  </Provider>
);

const AdminProvider = props => (
  <Provider store={props.store}>
    {true && (
      <Route
        path="/"
        render={() =>
          props.isAuthenticated ? (
            <>
              <Route
                exact={true}
                path="/"
                render={() => <Redirect to="/admin" />}
              />
              <Route
                exact={true}
                path="/login"
                render={() => <Redirect to="/admin" />}
              />
              <Route
                exact={true}
                path="/codigo"
                render={() => <Redirect to="/admin" />}
              />
            </>
          ) : (
            <Redirect to="/adminlogin" />
          )
        }
      />
    )}

    <LoginRoute path="/adminlogin" component={AdminLogin} />

    <Route path="/admin" component={AdminRoutes} />
  </Provider>
);

class Root extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    resizeObserver.init();
  }
  componentWillUnmount() {
    resizeObserver.finish();
  }
  render = () => {
    const { store } = this.props;
    const { auth } = store.getState();
    const isAuthenticated = typeof !!auth.auth.user && !!auth.auth.token;
    const isLogin =
      this.props.location.pathname === "/login" ||
      this.props.location.pathname === "/blank.html" ||
      this.props.location.pathname === "/directory"||
      this.props.location.pathname === "/codigo";
    if (!isAuthenticated && this.props.location.pathname === "/adminlogin") {
      AuthService.loginAdmin = true;
    }
    AuthService.isAdmin = auth.auth.isAdmin;
    configureAxios(store);
    if (AuthService.isAdmin || AuthService.loginAdmin) {
      return <AdminProvider store={store} isAuthenticated={isAuthenticated} />;
    }
    return (
      <UserProvider
        store={store}
        isAuthenticated={isAuthenticated}
        isLogin={isLogin}
      />
    );
  };
}

Root.propTypes = {
  store: PropTypes.object.isRequired
};
export default withRouter(Root);
