/**
 * Modulo que contiene las urls necesarias para la comunicación con el servidor
 */
const OperationConstants = {
  GET: 1,
  PUT: 2,
  POST: 3,
  OP_GET: "get",
  OP_POST: "post",
  OP_PUT: "put"
};
export default OperationConstants;
