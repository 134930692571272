import axios from "axios";
import { UPDATE_AUTH, CLOSE_SESSION } from "../actions/auth";
import { clearGlobal } from "../services/Services";
import { assets } from "../assets";
import { showModalError500 } from "./showModal";
import { showModalNetworkingError } from "./showModal";

let ready = false;

export const configureAxios = async store => {
  if (ready) {
    return;
  }
  ready = true;

  axios.interceptors.response.use(
    async function(response) {
      // Do something before request is sent
      return response;
    },
    async function(error) {
      // Do something with request error
      if (
        !!error.response &&
        !!error.response.data &&
        !!error.response.data.code
      ) {
        return error.response;
      }
      if (!!error.response && error.response.status === 401) {
        await store.dispatch({
          type: CLOSE_SESSION
        });

        //await localStorage.removeItem("auth");
        clearGlobal();

        await store.dispatch({
          type: UPDATE_AUTH,
          data: {
            error: {
              message: "Por favor vuelva a iniciar sesión.",
              title: "SESIÓN CADUCADA",
              icon: assets.cierreSesion
            },
            redirectTo: "/login"
          }
        });

        throw error;
      }

      if (!!error.response && error.response.status === 500) {
        showModalError500();

        throw error;
      }
      showModalNetworkingError();

      return Promise.reject(error);
    }
  );
  return;
  //axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
};
