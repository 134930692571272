import React, { Component } from "react";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block !important;
  margin: 0 auto;
  border-color: red;
`;

class Loading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="loading">
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={45}
          color={"#525149"}
          loading={true}
        />
      </div>
    );
  }
}
export default Loading;
