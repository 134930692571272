import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { StyledHasBreadcrumb } from "../components/styledComponents";
import AuthService from "../services/AuthService";
const breadcrumbUser = init({
  "/dashboard": {
    title: "Inicio",
    route: "/dashboard",
    routes: {
      "/magazine": { title: "Revista digital", route: "/magazine" },
      "/faqview": { title: "Preguntas frecuentes", route: "/faqview" },
      "/user": { title: "Usuarios", route: "/user" },
      "/directory": { title: "Red de prestadores", route: "/directory" },
      "/changedoctor": { title: "Elegir médico", route: "/changedoctor" },
      "/appointmentdashboard": {
        title: "Mis citas",
        route: "/appointmentdashboard",
        routes: {
          "/appointmentdashboard/assingappointment": {
            title: "Asignación de cita",
            route: "/appointmentdashboard/assingappointment"
          },
          "/appointmentdashboard/appointmenthistory": {
            title: "Histórico de citas",
            route: "/appointmentdashboard/appointmenthistory"
          }
        }
      }
    }
  }
});
const breadcrumbAdmin = init({
  "/admin": {
    title: "Inicio",
    route: "/admin",
    routes: {
      "/admin/magazine": {
        title: "Administrar revistas",
        route: "/admin/magazine"
      },
      "/admin/notification": {
        title: "Administrar notificaciones",
        route: "/admin/notification"
      },
      "/admin/sendEmail": {
        title: "Administrar correos",
        route: "/admin/sendEmail"
      },
      "/admin/faq": {
        title: "Administrar FAQ",
        route: "/admin/faq"
      },
      "/admin/user": {
        title: "Administrar usuarios",
        route: "/admin/user"
      }
    }
  }
});

function init(routes, parent) {
  var routesResult = {};
  var keys = Object.keys(routes);
  for (var i = 0; i < keys.length; i++) {
    var item = routes[keys[i]];
    routesResult[keys[i]] = item;
    if (!!parent) {
      routesResult[keys[i]]["parent"] = parent;
    }
    if (!!item.routes) {
      var childRoutes = init(item.routes, item);
      routesResult = Object.assign(routesResult, childRoutes);
    }
  }
  return routesResult;
}

function calculateNavs(route) {
  var navs = [route];
  var currentRoute = route;
  var i = 0;
  while (!!currentRoute && !!currentRoute.parent) {
    navs.push(currentRoute.parent);
    currentRoute = currentRoute.parent;
    i++;
  }
  return navs.reverse();
}

const breadcrumb = props => {
  if (!props.route) {
    return null;
  }
  const selectedBreadcrumb = AuthService.isAdmin
    ? breadcrumbAdmin
    : breadcrumbUser;
  const navs = calculateNavs(selectedBreadcrumb[props.route]);
  const navigate = (e, route) => {
    e.preventDefault();
    props.history.push(route);
  };
  const renderItem = (item, index) => {
    if (index === navs.length - 1) {
      return (
        <BreadcrumbItem key={index.toString()} active>
          {!!item ? item.title : ""}
        </BreadcrumbItem>
      );
    }

    if (index !== navs.length - 1) {
      return (
        <BreadcrumbItem key={index.toString()}>
          <a
            href="#"
            onClick={e => {
              navigate(e, item.route);
            }}
          >
            {item.title}
          </a>
        </BreadcrumbItem>
      );
    }

    return null;
  };
  return (
    <StyledHasBreadcrumb>
      <Breadcrumb>
        {navs.map((item, index) => renderItem(item, index))}
      </Breadcrumb>
    </StyledHasBreadcrumb>
  );
};
export default breadcrumb;
