export default class Validator{
    constructor(){
        this.state = {}
    }
    registerChange(tag){
        this.state[tag] = true;
    }
    reset(tag){
        delete this.state[tag];
    }
    validate(tag, validator){
        if(typeof this.state[tag] !== 'undefined'){
            return validator();
        }
        return true;
    }
}