export default function(store) {
  let currentData

  store.subscribe(() => {
    const { offlineLoaded, auth, authenticated } = store.getState().auth

    if (offlineLoaded && currentData != auth && authenticated) {
      localStorage.setItem("auth", btoa(JSON.stringify(auth)));
      currentData = auth
    }
  })
}
