import PubSub from 'pubsub-js'
import { resizeObserverConst } from './resizeObserverConst';

let ready = false;
let currentBreakpoint = null;



class ResizeObserver {
  constructor(){
    
    this.updateDimensions = this.updateDimensions.bind(this);
    // Dimensiones estándar de bootstrap 4.0
    this.dimensions = [
      {breakpoint: 'xs', minWidth: 0},
      {breakpoint: 'sm', minWidth: 576},
      {breakpoint: 'md', minWidth: 768},
      {breakpoint: 'lg', minWidth: 992},
      {breakpoint: 'xl', minWidth: 1200},
    ];
  }
  init(){
    if(ready){
      return;
    }
    ready = true;
    window.addEventListener("resize", this.updateDimensions);
  }
  getCurrentBreakpoint(){
    if(!currentBreakpoint){
      this.updateDimensions();
    }
    return currentBreakpoint;
  }
  updateDimensions(){
    var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
    // Pendiente que envíe el breakpoint correspondiente al ancho
    var i = 0;
    for(; i<this.dimensions.length; i++){
      if(width < this.dimensions[i].minWidth ){
        break;
      }
    }

    if(currentBreakpoint !== this.dimensions[i-1].breakpoint){
      currentBreakpoint = this.dimensions[i-1].breakpoint;
      PubSub.publish(resizeObserverConst.TYPE_EVENT.RESPONSIVE_RESIZE, {breakpoint: this.dimensions[i-1].breakpoint});
    }
    
    PubSub.publish(resizeObserverConst.TYPE_EVENT.RESIZE, {width, height});
  }
  finish(){
    window.removeEventListener("resize", this.updateDimensions);
    ready = false;
  }

}
let resizeObserver = new ResizeObserver();
export default resizeObserver;