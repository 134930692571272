import { DOCTOR_ID, VARIABLES } from "./CustomConstants";

const TextContent = {
  // Textos Login

  //Modal
  BT_ACCEPT: "ACEPTAR",
  BT_PHONE: "01-8000-915556",
  BT_SEND: "REENVIAR",
  ERROR_NETWORK: "Ocurrió un error de red. Por favor intente más tarde.",
  ERROR_500:
    "En este momento los servicios no están disponibles. Por favor intente más tarde.",
  ERROR_HEON:
    "En el momento no se encuentran disponibles algunas funcionalidades, por favor intente más tarde.",
  ERROR_DISABLE_SERVICES:
    "En el momento no se encuentran disponibles algunas funcionalidades, por favor intente más tarde.",
  ERROR_EMAIL:
    "Su medio de contacto no aparece en nuestra Base de Datos, si es trabajador realice la actualización en autoservicio, si es pensionado o beneficiario diríjase al centro de atención local (CAL) más cercano.",
  ERROR_ID:
    "No es posible realizar esta operación. Por favor comuníquese con nuestros asesores. Línea nacional gratuita 018000915556.",  
  ERROR_PASS:
    "El código ingresado no es el correcto, por favor verifique en su buzón de correo.",
  //Data no encontrada
  ERROR_MEGAOGA:
    "Realice el proceso de asignación acercándose o comunicándose con el Centro de Atención Local (CAL) más cercano.",
  //Habeas y Terminos
  TEXT_HABEAS:
    "AUTORIZACIÓN HABEAS DATA: <br /><br />En cumplimiento con la Ley 1581 de 2012 de protección de datos personales se le informa que sus datos personales se encuentran incorporados a una base de datos cuya finalidad es Gestión de Servicios de Salud, cuyo responsable del tratamiento es ECOPETROL y la vigencia será igual al periodo en que se mantenga la finalidad o el periodo de vigencia que señale una causa legal, contractual o jurisprudencial de manera específica. <br /><br />Como Titular se le informa que podrá ejercer sus derechos de acceso y reclamos a través de los centros de atención local o a través del correo de contacto habeasdata@ecopetrol.com.co. Así mismo, podrá consultar la Declaración de Tratamiento de la Información Personal en la página web www.ecopetrol.com.co. ECOPETROL S.A. se encuentra ubicada en la Carrera 13 No. 36 – 24 Edificio Principal, en la ciudad de Bogotá D.C.",
  TEXT_TERMS: `
  <div class="terms">
  <p><strong>TÉRMINOS Y CONDICIONES GENERALES DE USO:</strong></p>

  <p><br />
  Las presentes condiciones generales (en adelante las <strong>Condiciones Generales</strong>) regulan el acceso y el uso de la aplicación&nbsp; e+ salud de Ecopetrol S.A.&nbsp;</p>
  
  <p><br />
  <strong>La utilización de la app e+ salud&nbsp; supone la aceptación plena por el usuario, definido más adelante, de todos los Términos y Condiciones Generales vigentes en el momento en que el usuario acceda a la misma. Por lo que si el usuario no está de acuerdo con cualquiera de los Términos y Condiciones Generales &nbsp;deberá abstenerse de usar esta aplicación.</strong></p>
  
  <p>&nbsp;</p>
  
  <p>Ecopetrol S.A.&nbsp; se reserva el derecho de modificar, sin previo aviso, en cualquier momento los presentes Términos y Condiciones Generales de Uso, así como su configuración, localización y cualesquiera otros términos generales o particulares, reglamentos de uso, instrucciones o avisos que resulten de la aplicación. Así mismo, Ecopetrol S.A. se reserva el derecho de suspender, interrumpir o dejar de operar la aplicación e+ salud&nbsp; en cualquier momento. Por ser un desarrollo de Ecopetrol que se ofrece de forma gratuita a los usuarios, se reserva el derecho a terminar el acceso a la aplicación sin previo aviso.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>1. USUARIOS</strong></p>
  
  <p>Son los trabajadores de Ecopetrol S.A y sus beneficiarios inscritos y activos, es decir, todas aquellas personas que efectivamente se benefician de los servicios de salud prestados por&nbsp; Ecopetrol S.A&nbsp; a través de los profesionales adscritos y de la red de prestadores de servicios en salud a nivel nacional. El titular de los derechos de propiedad sobre la aplicación es Ecopetrol S.A.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>2. OBJETO</strong></p>
  
  <p>La aplicación e+ salud, fue desarrollada con el objeto de establecer un canal de comunicación, acorde con la vanguardia de las organizaciones que administran&nbsp; servicios de salud, que permite el acceso a algunos de tales&nbsp; servicios de manera ágil y fácil desde los dispositivos que maneja la mayoría de la población en la actualidad.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>3. TERRITORIO</strong></p>
  
  <p>El uso de la aplicación e+ salud está limitado para el<strong> </strong>territorio<strong> </strong>colombiano<strong>. </strong></p>
  
  <p>&nbsp;</p>
  
  <p><strong>4. FUNCIONES QUE REALIZA LA APLICACIÓN</strong></p>
  
  <p>Los USUARIOS solamente están facultados para&nbsp; acceder y usar la aplicación&nbsp;en los términos previstos en estos Términos y&nbsp; Condiciones Generales de Uso.&nbsp;A través de la aplicación e+ salud los USUARIOS podrán:</p>
  
  <ul>
    <li>Programar y cancelar, según sea el caso,&nbsp;las&nbsp; citas médicas con el MEGA y/o el OGA.</li>
    <li>La aplicación e+ salud solo le permitirá a los USUARIOS agendar una cita a la vez, si por alguna razón excepcional requiere agendamiento de más de una cita, deberá realizar directamente con el médico de cabecera por alguno de los otros canales dispuestos por el prestador de salud.</li>
  </ul>
  
  <p>&nbsp;</p>
  
  <p>El beneficiario puede solicitar cita hasta con una (1) hora de anticipación antes del inicio de la cita,</p>
  
  <p>&nbsp;</p>
  
  <p>La cancelación de citas requerirá al menos seis (6) horas antes del inicio de la misma.</p>
  
  <p>&nbsp;</p>
  
  <ul>
    <li>Conocer el historial de las mismas y los horarios disponibles de esos profesionales de la salud para facilitar la programación de las citas.</li>
    <li>Acceder a su detalle médico, datos de contacto, especialidad y ubicación.</li>
  </ul>
  
  <p>&nbsp;</p>
  
  <p>De esta manera la aplicación tiene dos funcionalidades: La <strong>primera de</strong> <strong>consulta</strong> de información sobre: (i) médicos adscritos al servicio de los USUARIOS, (ii) la red de prestadores de servicios de salud de Ecopetrol S.A. a nivel nacional, así como sus datos de contacto y (iii) la vigencia de los servicios médicos; y la <strong>segunda de naturaleza&nbsp; transaccional</strong> para solicitud y cancelación de citas médicas,&nbsp;para cambio de MEGA y/o OGA asignado, para acceso a la sección de preguntas frecuentes de los servicios de salud, a la revista digital e+ salud, a la programación de actividades de promoción de la salud y prevención de la enfermedad y a enlaces a la línea de atención nacional y al chat virtual, los cuales funcionan las veinticuatro (24) horas del día, los siete días de la semana<strong>. </strong></p>
  
  <p>&nbsp;</p>
  
  <p>Las funcionalidades informativas y transaccional de la aplicación e+ salud, son provistos por un servicio contratado con el proveedor de la actual plataforma de gestión de información de salud de Ecopetrol S.A. Solo el servicio de datos de beneficiarios se obtiene directamente de los sistemas de Ecopetrol S.A.</p>
  
  <p>&nbsp;</p>
  
  <p>El soporte a la aplicación será realizado en primer nivel desde el Contact Center de la línea Nacional de Salud &nbsp;018000 915556 y atiende 24 horas del día los 7 días de la semana<strong>, </strong><strong>sin que ello implique que Ecopetrol asume cualquier tipo de responsabilidad por el hecho de que la aplicación deje de funcionar temporalmente ya sea por mantenimientos o por hechos imprevistos. </strong>y será escalado a segundo nivel a Service Desk de Ecopetrol quien traslada la solicitud al equipo de desarrollo de la app en la fábrica digital.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>Por la naturaleza del servicio médico es probable que la cita no inicie a la hora programada a través de la aplicación e+ salud, lo cual no compromete la responsabilidad de Ecopetrol frente a los USUARIOS</strong>.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>5. &nbsp;FUNCIONES QUE NO REALIZA LA APLICACIÓN E+ SALUD</strong></p>
  
  <p>&nbsp;</p>
  
  <p>Acceso a los demás servicios regulados en el reglamento de salud&nbsp; y que no hacen parte de la funcionalidad de la aplicación dentro de los que se incluyen: <em>programación de citas con especialistas u otros prestadores diferentes a médicos de cabecera con población asignada, prescripción de medicamentos, y otras ordenes generadas por los prestadores para la solicitud de servicios, registro de incapacidades u otras transacciones diferentes a las mencionadas en las funciones que realiza la aplicación.</em></p>
  
  <p>&nbsp;</p>
  
  <p><strong>6. CONDICIONES DE ACCESO Y UTILIZACIÓN DE LA APLICACIÓN E+ SALUD</strong></p>
  
  <p>&nbsp;</p>
  
  <p><strong>6.1&nbsp; Acceso y utilización de la Aplicación.</strong></p>
  
  <p>&nbsp;</p>
  
  <p>Ecopetrol S.A.&nbsp;exige un uso responsable y lícito de la aplicación e+ salud&nbsp;y de los contenidos mostrados en la misma.&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p>Con la instalación de&nbsp; la aplicación, el usuario reconoce ser mayor de dieciocho (18) años y acepta que el acceso y uso &nbsp;de la aplicación e+ salud y/o de los contenidos incluidos en la misma tiene lugar libre y conscientemente, bajo su exclusiva responsabilidad.</p>
  
  <p>&nbsp;</p>
  
  <p>El acceso a la aplicación e+ salud y/o a los contenidos incluidos en la misma no implica ningún tipo de garantía respecto a la idoneidad de la aplicación y/o de los contenidos incluidos en la misma para fines particulares o específicos de los USUARIOS.</p>
  
  <p>&nbsp;</p>
  
  <p>Ecopetrol S.A. en cualquier momento podrá establecer limitaciones y/o condiciones adicionales para el uso y/o acceso a la aplicación e+ salud y/o a los contenidos, las cuales deberán ser observadas por los USUARIOS en todo caso.</p>
  
  <p>&nbsp;</p>
  
  <p>.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>6.2.&nbsp;Uso autorizado de la aplicación e+ salud&nbsp; y de los contenidos</strong></p>
  
  <p>&nbsp;</p>
  
  <p><strong>6.2.1 Condiciones de Uso</strong></p>
  
  <p>&nbsp;</p>
  
  <p>Los USUARIOS harán uso exclusivo de sus datos de acceso y por lo tanto no podrán prestarlos o facilitarlos a un tercero y/o externo.</p>
  
  <p>&nbsp;</p>
  
  <p>La aplicación e+ salud se puede descargar de manera gratuita desde las tiendas de Google (Play Store) y de dispositivos Apple (App Store). También tiene una versión web a la cual se podrá acceder desde cualquier dispositivo que pueda navegar en internet en la dirección <a href="https://emassalud.ecopetrol.com.co">https://emassalud.ecopetrol.com.co</a></p>
  
  <p>&nbsp;</p>
  
  <p>La aplicación para dispositivos móviles con sistema operativo Android se encuentra disponible desde la versión 4.4, y para dispositivos móviles con sistema operativo IOS desde la versión 10. La versión web, está disponible para los navegadores Chrome, Internet Explorer y Firefox en versiones recientes.</p>
  
  <p>&nbsp;</p>
  
  <p>El código fuente es privado y desarrollado y solo puede ser modificado por Ecopetrol.</p>
  
  <p>&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p>El beneficiario no podrá reproducir, publicar o distribuir la información contenida en la aplicación, pues es información de propiedad de Ecopetrol y su uso es específico para cada beneficiario.</p>
  
  <p>&nbsp;</p>
  
  <p>.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>6.2.3. Obligaciones de los USUARIOS</strong></p>
  
  <p>&nbsp;</p>
  
  <p>El uso de esta aplicación está limitado a los beneficiarios de los servicios de salud de Ecopetrol, mayores de 18 años.</p>
  
  <p>&nbsp;</p>
  
  <p>La aplicación es desarrollada por Ecopetrol y por tanto está prohibida la reproducción parcial o completa de la misma, sin previa autorización de la empresa.</p>
  
  <p>&nbsp;</p>
  
  <p>Solo pueden ingresar los USUARIOS que cuenten con datos de contacto en la base de datos de Ecopetrol, tipo de identificación, número de identificación, correo electrónico y/o número de teléfono móvil.</p>
  
  <p>&nbsp;</p>
  
  <p>Los USUARIOS se obligan al cumplimiento de los presentes Términos y Condiciones Generales de Uso, así como a cumplir las especiales advertencias o instrucciones contenidas en la aplicación e+ salud y a obrar siempre conforme a la ley, a las buenas costumbres y a las exigencias de la buena fe, empleando su máxima en atención teniendo en cuenta la naturaleza y contraprestación del servicio del que disfruta. Para tal efecto, se abstendrá de utilizar la aplicación e+ salud de cualquier forma que pueda impedir, dañar o deteriorar el normal funcionamiento del mismo, los bienes o derechos de Ecopetrol S.A., los profesionales de la salud adscritos y la red de prestadores de servicios de salud a nivel nacional, el resto de USUARIOS o en general de cualquier tercero.</p>
  
  <p>&nbsp;</p>
  
  <p>Concretamente y sin que ello implique restricción alguna a la obligación asumida por el USUARIO con carácter general de conformidad con el apartado anterior, el USUARIO se obliga en la utilización de la aplicación e+ salud:</p>
  
  <p>&nbsp;</p>
  
  <p>(i) A no introducir, almacenar o difundir en o desde la aplicación e+ salud, cualquier información o material que sea difamatorio, injurioso, obsceno, amenazador, xenófobo, pornográfico, de apología al terrorismo, incite a la violencia a la discriminación por razón de raza, sexo, ideología, religión o que de cualquier forma atente contra la forma, el orden público, los derechos fundamentales, las libertades públicas, el honor, la intimidad o la imagen de terceros y en general la normativa vigente.</p>
  
  <p>(ii) A no introducir, almacenar o difundir mediante la aplicación e+ salud ningún programa de ordenador, datos, virus, código, o cualquier otro instrumento o dispositivo electrónico o físico que sea susceptible de causar daños a la aplicación e+ salud, en cualquiera de los servicios, o en cualquiera de los equipos, sistemas o redes de Ecopetrol S.A., de cualquier USUARIO, de los profesionales de la salud adscritos y/o de&nbsp; la red de prestadores de servicios de salud a nivel nacional o en general de cualquier tercero, capaz de causar cualquier tipo de alteración o impedir el normal funcionamiento de los mismos.</p>
  
  <p>(iii) A no realizar actividades de <em>spamming</em>, publicitarias, promocionales o de explotación comercial de la aplicación e+ salud, de tal forma que no se utilizarán los contenidos y en particular la información obtenida a través de la aplicación e+ salud para remitir publicidad, enviar mensajes con fines de venta directa o con cualquier otro fin comercial, ni para recoger o almacenar datos personales.</p>
  
  <p>(iv) A no destruir, alterar, inutilizar o dañar los datos, informaciones, programas o documentos electrónicos de Ecopetrol S.A., que se muestren o incluyan en de la aplicación e+ salud.</p>
  
  <p>(v) A no introducir, almacenar o difundir mediante la aplicación e+ salud cualquier contenido que infrinja las normas de propiedad intelectual, industrial o derechos de terceros, ni en general ningún contenido del cual no tuviera, de conformidad con la ley, el derecho a ponerlo a disposición de terceros.</p>
  
  <p>(vi) A no realizar manifestaciones o indicaciones falsas, inexactas o incorrectas sobre &nbsp;la aplicación e+ salud</p>
  
  <p>&nbsp;</p>
  
  <p><strong>6.2.4&nbsp; Obligaciones de Ecopetrol S.A.</strong></p>
  
  <p>&nbsp;</p>
  
  <p>Ecopetrol S.A se compromete a mantener la información actualizada y a realizar los ajustes cuando haya lugar. Igualmente se compromete a publicar la información de acuerdo con las bases de datos de Ecopetrol S.A. y a prestar soporte a los USUARIOS en los casos que se requiera.</p>
  
  <p>&nbsp;</p>
  
  <p>En todo caso, Ecopetrol S.A. quedará exceptuado de toda responsabilidad respecto del mal uso que puedan dar los USUARIOS a la aplicación e+ salud, en el evento en que&nbsp; compartan su contraseña con terceros o extravíen los dispositivos a través de los cuales hayan descargado la aplicación e+ salud y permitir que un tercero u otro USUARIO acceda a su información. La información publicada es la que reposa en las bases de datos de Ecopetrol S.A. la cual está sujeta a actualizaciones y también &nbsp;depende de las modificaciones o actualizaciones &nbsp;que hayan sido introducidas &nbsp;por los USUARIOS. Los errores, inexactitudes y/o imprecisiones en las modificaciones a la información introducida en la aplicación e+ salud por los USUARIOS será de su exclusiva responsabilidad y no comprometerá en manera alguna la responsabilidad de Ecopetrol S.A como titular de dicha aplicación.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>7. POLÍTICA DE PRIVACIDAD DE LOS DATOS PERSONALES</strong></p>
  
  <p>Para efectos del funcionamiento de la aplicación e+ salud, Ecopetrol realizará el tratamiento de los datos personales suministrados por los USUARIOS. Este tratamiento tiene por finalidad garantizar a los USUARIOS la programación y cancelación, según sea el caso,&nbsp; de las&nbsp; citas médicas con el MEGA y/o el&nbsp; OGA que les hayan sido asignados previamente, así como conocer el historial de las mismas y los horarios disponibles de esos profesionales de la salud para facilitar la programación de las citas. Así como &nbsp;acceder a su detalle médico y utilizar&nbsp; las funcionalidades de consulta y de naturaleza transaccional descritas en el numeral 4 de estos Términos y Condiciones General de Uso.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>8.&nbsp;DERECHOS DE PROPIEDAD INDUSTRIAL E INTELECTUAL</strong></p>
  
  <p>Todos los contenidos que se muestran en la aplicación e+ salud incluyendo sin limitación, diseños, textos, gráficos, logos, iconos, botones, s<em>oftware</em>, nombres comerciales, signos distintivos marcas, o cualesquiera otros activos intangibles protegidos por la normativa de propiedad industrial e intelectual están sujetos a los derechos de propiedad intelectual e industrial de Ecopetrol S.A. o de terceros titulares de los mismos, que han autorizado debidamente su inclusión en la aplicación e+ salud.</p>
  
  <p>&nbsp;</p>
  
  <p>En ningún caso se entenderá que cuando se haga uso de la aplicación e+ salud se concede licencia alguna o se efectúa renuncia, transmisión, cesión total o parcial de dichos derechos, ni se confiere ningún derecho de explotación sobre dichos activos, sin la previa autorización expresa y por escrito de Ecopetrol S.A. o de los titulares de los derechos correspondientes.</p>
  
  <p>&nbsp;</p>
  
  <p>Los presentes Términos y Condiciones Generales de Uso no confieren a los USUARIOS ningún otro derecho de utilización, alteración, explotación, reproducción, distribución o comunicación pública de la aplicación e+ salud y/o de sus contenidos distintos de los aquí expresamente previstos. Cualquier otro uso o explotación de tales derechos estará sujeto(a) a la previa y expresa autorización específicamente otorgada a tal efecto por Ecopetrol S.A. o del titular de los derechos afectados.</p>
  
  <p>&nbsp;</p>
  
  <p>Ecopetrol autoriza a los usuarios a utilizar, visualizar, imprimir, descargar y almacenar los contenidos y/o los elementos insertados en la aplicación e+ salud cuando expresamente así se indique; siempre que en todo caso se indique el origen y/o autor de los mismos y que, en su caso, aparezca el símbolo del <em>copyright</em> y/o notas de propiedad industrial de sus titulares. Queda terminantemente prohibida la explotación de tales activos (reproducción, comunicación, distribución, transformación, etc.). Para cualquier otro uso distinto de los expresamente permitidos, será necesario obtener el consentimiento previo por escrito del titular de los derechos que se traten.</p>
  
  <p>&nbsp;</p>
  
  <p>El USUARIO se obliga a no utilizar las marcas de Ecopetrol &nbsp;de ningún modo que pueda causar confusión entre los demás USUARIOS y terceros o que pueda menospreciar o desacreditar a Ecopetrol S.A. ni en conexión con ningún servicio o producto que no haya sido patrocinado, aprobado o producido por Ecopetrol S.A.</p>
  
  <p>&nbsp;</p>
  
  <p>El USUARIO deberá denunciar cualquier violación a los Términos y Condiciones Generales de Uso de la aplicación e+ salud por parte de otros USUARIOS, para lo cual debe enviar un correo electrónico a <a href="mailto:oficinavirtualdesalud@ecopetrol.com.co">oficinavirtualdesalud@ecopetrol.com.co</a> con el fin de que Ecopetrol S.A. tome todas las medidas respectivas que estén a su alcance en relación con la utilización de la aplicación.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>9.&nbsp;POLÍTICA DE <em>COOKIES</em></strong></p>
  
  <p>Ecopetrol S.A. &nbsp;pretende ofrecerle una navegación más cómoda. A tal fin al igual que se hace en otros sitios web, en la aplicación e+ salud se utilizan <em>cookies</em>.</p>
  
  <p>&nbsp;</p>
  
  <p>Para que, en su caso, pueda otorgar su consentimiento informado e inequívoco le explicamos cómo funcionan nuestras <em>cookies</em>. Así, le indicamos a continuación qué son las mismas, el uso que hacemos de ellas y las opciones que tiene para gestionarlas.</p>
  
  <p><strong>Al navegar y continuar en la aplicación e+ salud estará consintiendo el uso de las <em>cookies</em>, por los plazos señalados a continuación y en las condiciones contenidas en la presente política de <em>cookies</em></strong>.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>9.1.&nbsp;Concepto de <em>cookie</em></strong></p>
  
  <p>Las<em> cookies</em> son pequeños archivos que una aplicación coloca en su ordenador, teléfono o cualquier otro dispositivo con información sobre su navegación en dicho sitio web. Las <em>cookies</em> son instaladas durante la navegación en Internet, bien por los sitios web que visita o bien por terceros con los que se relaciona la aplicación. A través de las <em>cookies</em> se puede obtener, entre otra, la siguiente información: la fecha y hora de visitas a la aplicación, el tiempo que ha estado en la aplicación y los sitios visitados justo antes.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>9.2.&nbsp;C<em>ookies</em> utilizadas por Ecopetrol </strong></p>
  
  <p>En la aplicación e+ salud utilizamos<em> cookies</em> analíticas de terceros. En concreto, la aplicación e+ salud utiliza Google Analytics. Es un servicio analítico web prestado por Google, Inc., (en adelante “<strong>Google</strong>”), que es una sociedad de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043 Estado Unidos de América.</p>
  
  <p>Dicho servicio nos permite conocer cómo interactúan los usuarios de nuestro Sitio web. Asimismo, habilita <em>cookies</em> en el dominio del sitio en el que se encuentra el usuario y utiliza un conjunto de <em>cookies</em>&nbsp;para recopilar información de forma anónima y elaborar informes de tendencias del Sitio web sin identificar a usuarios individuales. Junto con nuestros archivos de registro del servidor, nos permiten conocer el número total de usuarios que visitan nuestro Sitio web y aquellas partes del mismo que gozan de mayor popularidad. Así, obtenemos una información que puede ayudarnos a mejorar la navegación y dar un mejor servicio a usuarios.</p>
  
  <p>Para más información acerca del tipo de <em>cookies</em> que utiliza Google Analytics incluimos a continuación el siguiente enlace al <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es" title="Más información sobre Cookies de Google Analytics">sitio web de Google</a>.</p>
  
  <p>Según determina el proveedor Google, la herramienta Google Analytics se rige por las c<a href="http://www.google.com/analytics/terms/es.html" title="Condiciones Generales de Google">ondiciones generales de Google</a> accesibles en http://www.google.com/analytics/terms/es.html y la <a href="http://www.google.com/intl/es/analytics/privacyoverview.html" title="Política de Privacidad de Google">política de privacidad de Google</a> accesible en <a href="http://www.google.com/intl/es/analytics/privacyoverview.html">http://www.google.com/intl/es/analytics/privacyoverview.html</a> en relación con <a href="http://www.google.es/policies/privacy/">http://www.google.es/policies/privacy/</a>. [&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ] no es responsable del contenido o la veracidad del clausulado general o particular incluido en tales Links o dictados por terceros (Google o sus proveedores). Es posible que Google actualice su política de<em> cookies</em>. Por ello le recomendamos revisar su política cada vez que acceda a nuestro Sitio web con el objetivo de estar adecuadamente informado.</p>
  
  <p>En atención a las condiciones establecidas por Google para Google Analytics, [&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ] le informa que:</p>
  
  <ul>
    <li>Este Sitio web sólo recaba información de forma anónima con el fin de obtener para su análisis informes sobre hábitos de navegación del Sitio Web sin identificar a usuarios individuales.</li>
    <li>La captación de información no la hace Google ni en nombre, ni por cuenta de [&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ]. &nbsp;&nbsp;&nbsp;[&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ] no tiene acceso a toda la información, solo tiene acceso a información agregada que le facilita posteriormente Google y que no está asociada a ninguna dirección IP.</li>
    <li>Únicamente Google es quien determina la finalidad del tratamiento y uso de la información captada por Google Analytics en todo momento, así como el funcionamiento y duración de las <em>cookies</em>.</li>
  </ul>
  
  <p>&nbsp;</p>
  
  <p><strong>9.3.&nbsp;Gestión de las <em>cookies</em> en su equipo</strong></p>
  
  <p>Conforme a la información proporcionada por Google, usted puede controlar y eliminar sus <em>cookies</em> a través de su navegador. La forma de hacerlo la encontrará en la sección de “Ayuda” del mismo. Normalmente, le remitirá al apartado de “Herramientas” o “Preferencias”.</p>
  
  <p>Si lo desea puede encontrar más información sobre cómo rechazar la instalación de <em>cookies</em> de Google Analytics haciendo <a href="https://support.google.com/analytics/answer/181881?hl=es&amp;ref_topic=2919631.">clic aquí </a>o entrando en https://support.google.com/analytics/answer/181881?hl=es&amp;ref_topic=2919631.</p>
  
  <p><strong>9.4.&nbsp;Tratamiento de las <em>cookies </em>anteriores</strong></p>
  
  <p>Si ha desactivado alguna <em>cookie </em>ahora, [&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ] podrá utilizar la información que recabó con anterioridad. Sin embargo, dejaremos de obtener cualquier tipo de información adicional.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>10.&nbsp;EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</strong></p>
  
  <p>&nbsp;</p>
  
  <p><strong>10.1. Exclusión de garantías y de responsabilidad por el funcionamiento de la aplicación e+ salud </strong></p>
  
  <p>&nbsp;</p>
  
  <p>Ecopetrol S.A. no garantiza la disponibilidad y continuidad del funcionamiento de la aplicación e+ salud y de los servicios o contenidos ofrecidos en la misma, ni que el contenido existente se encuentre actualizado, quedando exonerada de toda responsabilidad por los daños y perjuicios de cualquier naturaleza que puedan originarse por tales circunstancias.</p>
  
  <p>&nbsp;</p>
  
  <p>Ecopetrol S.A. llevará a cabo, siempre que no concurran circunstancias que lo hagan imposible o de difícil ejecución y tan pronto tenga noticias de los errores, desconexiones y/o falta de actualización de los contenidos, todas aquellas labores tendentes a subsanar los errores, restablecer la comunicación y/o actualizar los referidos contenidos.</p>
  
  <p>&nbsp;</p>
  
  <p>Asimismo, Ecopetrol no garantiza ni la fiabilidad técnica de la aplicación e+ salud,&nbsp; quedando de la misma forma exonerada de toda responsabilidad por los daños y perjuicios de cualquier naturaleza que puedan originarse por esta causa.</p>
  
  <p>&nbsp;</p>
  
  <p>Además, Ecopetrol no se hace responsable de los posibles errores o deficiencias de seguridad que pudieran producirse por la utilización por parte del Usuario de un navegador de una versión no actualizada o insegura en el navegador o de los daños, errores o inexactitudes que pudieran derivarse del mal funcionamiento del mismo.</p>
  
  <p>&nbsp;</p>
  
  <p>Con el fin de disminuir el riesgo de introducción de virus en la aplicación, Ecopetrol S.A utiliza programas de detección de virus para controlar todos los contenidos que introduce la aplicación e+ salud. No obstante, Ecopetrol S.A. no garantiza la ausencia de virus, ni de otros elementos en la aplicación e+ salud introducidos por terceros ajenos a Ecopetrol que puedan producir alteraciones en los sistemas físicos o lógicos de los usuarios o en los documentos electrónicos y ficheros almacenados en sus sistemas.</p>
  
  <p>&nbsp;</p>
  
  <p>En consecuencia, Ecopetrol S.A. no será en ningún caso responsable de cualesquiera daños y perjuicios de toda naturaleza que pudieran derivarse de la presencia de virus u otros elementos que puedan producir alteraciones en los sistemas físicos o lógicos, documentos electrónicos o ficheros de los usuarios.</p>
  
  <p>&nbsp;</p>
  
  <p>Ecopetrol S.A. adopta diversas medidas de protección para proteger la aplicación e+ salud, los datos recabados y los contenidos contra ataques informáticos de terceros. No obstante, Ecopetrol S.A. no garantiza que terceros no autorizados puedan tener acceso al tipo de uso o navegación de la aplicación e+ salud que hace el USUARIO o las condiciones, características y circunstancias en las cuales se efectúa. En consecuencia, Ecopetrol S.A. no será en ningún caso responsable de los daños y perjuicios que pudieran derivarse de dicho acceso no autorizado.</p>
  
  <p>&nbsp;</p>
  
  <p>Ecopetrol no se hará responsable en ningún caso del uso que los USUARIOS y/o terceros pudieran hacer de la aplicación e+ salud o los contenidos, ni de los daños y perjuicios que pudieran derivarse del mismo.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>10.2.&nbsp;Exclusión de garantías y de responsabilidad por los Contenidos</strong></p>
  
  <p>Ecopetrol S.A no edita los contenidos de terceros publicados en la aplicación e+ salud y, en consecuencia, no garantiza ni se hace responsable de la licitud, fiabilidad, utilidad, veracidad, exactitud, exhaustividad y actualidad de dichos Contenidos. Ecopetrol S.A. no será en ningún caso responsable de cualesquiera daños y perjuicios que pudieran derivarse de: (i) la falta de licitud, veracidad, exactitud, exhaustividad y/o actualidad de los contenidos originados por terceros; (ii) la inadecuación para cualquier propósito y la defraudación de las expectativas generadas por los contenidos; (iii) decisiones o acciones tomadas o evitadas por parte del USUARIO confiando en la información o datos facilitados o proporcionados en los contenidos, incluyendo sin limitación la pérdida de beneficios.</p>
  
  <p><strong>11.&nbsp;ACCIONES EN CASO DE INCUMPLIMIENTO</strong></p>
  
  <p>Ecopetrol S.A se reserva el derecho a ejercer las &nbsp;acciones legales a que haya lugar para exigir las responsabilidades que se deriven del incumplimiento de cualquiera de las disposiciones de estos Términos y Condiciones Generales de Uso de la aplicación e+ salud por parte de los USUARIOS.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>12.&nbsp;NULIDAD PARCIAL</strong></p>
  
  <p>&nbsp;</p>
  
  <p>La declaración de cualquiera de las cláusulas contenidas en estos Término y Condiciones Generales de Uso como nula, inválida o ineficaz no afectará a la validez o eficacia de las restantes, que seguirán siendo vinculantes entre las partes. La renuncia por cualquiera de las partes a exigir en un momento determinado el cumplimiento de una cualquiera de las condiciones generales aquí estipuladas no implicará una renuncia con carácter general al cumplimiento de otra condición o condiciones, ni creará un derecho adquirido para la otra parte.</p>
  
  <p>&nbsp;</p>
  
  <p>En todo caso, Ecopetrol dará observancia de lo dispuesto en su Declaración de Tratamiento de la Información Personal, la cual se encuentra publicada en su página web www.ecopetrol.com.co.</p>
  
  <p>&nbsp;</p>
  
  <p>Ecopetrol &nbsp;tratará los datos de forma confidencial y exclusivamente con la finalidad antes mencionada &nbsp;con todas las garantías legales y de seguridad que impone la Constitución Política, las normas aplicables a la protección de datos de carácter personal y demás normas concordantes.</p>
  
  <p>&nbsp;</p>
  
  <p>Ecopetrol S.A. se compromete a no ceder, vender, ni compartir los datos que son objeto de tratamiento a través de la aplicación e+ salud, con terceros sin su aprobación expresa. Ecopetrol S.A. cancelará o rectificará los datos cuando resulten inexactos, incompletos o hayan dejado de ser necesarios o pertinentes para su finalidad, sin perjuicios de los derechos que le asisten a los titulares de los datos personales previstos en la ley.</p>
  
  <p>&nbsp;</p>
  
  <p>Ecopetrol ha adoptado los niveles de seguridad de protección de los datos personales legalmente requeridos, con la instalación de medidas técnicas y organizativas necesarias para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos facilitados.</p>
  
  <p>&nbsp;</p>
  
  <p>Las respuestas a las preguntas que deban dar los USUARIOS para efectos de la finalidad descrita en el numeral&nbsp; 4 de los presentes Términos y Condiciones Generales de Uso serán obligatorias para que los USUARIOS puedan disfrutar de los beneficios de la aplicación e+ salud</p>
  
  <p>&nbsp;</p>
  
  <p>La información personal que contenga la aplicación y/o sea canalizada a través de ella está por parte de los&nbsp; USUARIOS estará protegida con una clave de acceso restringido al respectivo USUARIO. El USUARIO podrá modificar su información como USUARIO utilizando su nombre de USUARIO y su clave. El USUARIO se compromete a notificar a Ecopetrol S.A. en forma inmediata y por medio fehaciente, cualquier uso no autorizado de su clave. En todo caso, la conservación, protección y uso de la clave del USUARIO&nbsp; es de su exclusiva responsabilidad, de tal manera que los perjuicios que deba sufrir el USUARIOS por causa del uso de su clave por parte de terceros, son exclusivamente imputables al USUARIO.</p>
  
  <p>&nbsp;</p>
  
  <p>No obstante lo anterior, la Cámara de Comercio de Bogotá reconoce y adhiere a las iniciativas contra el correo no solicitado ("spamming"), en el entendido de que la información enviada a sus Usuarios no contiene publicidad comercial e incorpora mecanismos sencillos y explícitos para dejar de recibir dichos correos.</p>
  
  <p>&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p><strong>13. LEY APLICABLE Y JURISDICCIÓN</strong></p>
  
  <p>Las presentes Condiciones Generales se regirán por lo dispuesto en la legislación colombiana. Cualquier controversia relativa a la condiciones de uso y acceso a la aplicación e+ salud &nbsp;en el presente documento, será resuelta por los Jueces de la República de Colombia. Principio del formulario</p>
  
  <p>Final del formulario</p>
  
  <p>&nbsp;</p>
  
  </div>
  `,
  CHANGE_DOCTOR_TEXT: {
    [DOCTOR_ID.ADULT.MEGA]: "El nuevo doctor será: ",
    [DOCTOR_ID.ADULT.OGA]: "El nuevo odontólogo será: ",
    [DOCTOR_ID.CHILDREN.MEGA]: "El nuevo doctor será: ",
    [DOCTOR_ID.CHILDREN.OGA]: "El nuevo odontólogo será: "
  },
  CHANGE_DOCTOR_SUCESS: "La asignación del médico fue exitosa.",
  CHANGE_DOCTOR_DISABLED: "No se puede cambiar el médico de un mayor de edad.",
  CHANGE_DOCTOR_ERROR:
    "Ocurrió un error en la asignación del doctor. Por favor intente más tarde.",
  CHANGE_DOCTOR_PERMITTED:
    "Se recomienda realizar el cambio el día 24 del presente mes para continuar asignando citas con el mismo profesional, si realiza el cambio podrá asignar citas con el nuevo profesional a partir del día 6 del siguiente mes.",
  CHANGE_DOCTOR_IN_PROCESS:
    "Se realizó un cambio, por lo tanto podrá asignar citas con el nuevo profesional a partir del día 6 del siguiente mes.",
  CHANGE_DOCTOR_DEADLINE:
    "Usted realizó un cambio durante los dos meses. Por favor gestionar el cambio a partir del día ",
  CHANGE_DOCTOR_TIME_DISABLED:
    "El cambio de profesional podrá realizarlo entre el día 6 al 24, se recomienda hacerlo cerca al día 24 para que el día 6 pueda gestionar su servicio con el nuevo profesional.",
  CANCEL_APPOINTMENT_VALID_TERM:
    "Para cancelar la cita debe hacerlo con mínimo " +
    VARIABLES.CANCEL_APPOINTMENT_VALID_TERM +
    " horas de anticipación.",
  CANCEL_APPOINTMENT_SUCCESS: "Cita cancelada exitósamente.",
  APPOINTMENT_WITHOUT_APPOINTMENT:
    "Aún no se ha publicado agenda. Contacte a su médico. Recuerde que también puede hacer uso de los servicios de atención médica preferente.​",
  APPOINTMENT_WITHOUT_RESULTS:
    "Aún no se ha publicado agenda para este periodo de tiempo. Contacte a su médico. Recuerde que también puede hacer uso de los servicios de atención médica preferente.​",
  CANCEL_APPOINTMENT_ERROR: "Ocurrió un error. Por favor intente más tarde.",
  ASSIGN_APPOINTMENT_HAS_APPOINTMENT:
    "Ya tiene una cita agendada con este profesional.",
  ASSIGN_APPOINTMENT_HAS_APPOINTMENT_SAME_DAY:
    "Solo puede asignar una cita el mismo día con un médico de cabecera. Por favor reagende o comuníquese directamente con el profesional.",
  ASSIGN_APPOINTMENT_DEADLINE_HAS_EXPIRED:
    "La cita debe ser asignada por lo menos con 1 hora de antelación, por favor seleccione otra.",
  ASSIGN_APPOINTMENT_CLOSE_TO_APPOINTMENT:
    "Esta cita no podrá ser cancelada por estar próxima a la hora de la cita.",
  ASSIGN_APPOINTMENT_CONFIRMATION: "La cita quedará asignada para el día",
  ASSIGN_APPOINTMENT_DOCTOR_CHANGED:
    "Podrá asignar cita con el nuevo profesional a partir del 6 del siguiente mes, debido al cambio de ",
  TEXT_RESEND_OTP: "Hemos reenviado un nuevo código a su correo electrónico.",
  TEXT_NO_APPOINTMENT:
    "La asignación no se pudo realizar, si es pensionado o beneficiario diríjase al centro de atención local (CAL) más cercano o comuníquese a la línea nacional gratuita 01_8000_915556.",
  TEXT_SUCCESS_APPOINTMENT:
    "Su cita fue correctamente registrada, recuerde llegar 20 minutos antes."
};
export default TextContent;
