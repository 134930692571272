import { createStore } from "redux";
import { UPDATE_AUTH } from "../actions/auth";
//import thunk from 'redux-thunk'
import rootReducer from "../reducers";
import syncAuth from "./syncAuth";

const storeCreator = preloadedState =>
  createStore(
    rootReducer,
    preloadedState
    //applyMiddleware(thunk)
  );
const configureStore = storeCreator();

syncAuth(configureStore);
var rawData = localStorage.getItem("auth");
if (rawData && typeof rawData !== "undefined") {
  var auth = JSON.parse(atob(rawData));
  configureStore.dispatch({
    type: UPDATE_AUTH,
    data: { auth, authenticated: true }
  });
}

export default configureStore;
