import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {updateAuth, closeSession} from '../actions/auth'

const mapStateToProps = (state) => {
    return {
      auth: state.auth
    };
  };


  export const connectComponent = (component)=>{
    return withRouter(connect(mapStateToProps, {
        updateAuth,
        closeSession
      })(component))
  }
  