import axios from "axios";
import moment from "moment";
import "moment/locale/es-do";
import store from "../../src/store/configureStore";
import CONST from "../utils/Constants";
import { DOCUMENT_KEY } from "../utils/CustomConstants";
import OP_CONST from "./OperationConstants";

export async function getStoreItem(key) {
  var rawData = await localStorage.getItem(key);
  if (rawData) {
    var data = JSON.parse(atob(rawData));
    return data;
  }
  return null;
}
export async function setStoreItem(key, value) {
  await localStorage.setItem(key, btoa(JSON.stringify(value)));
}

/**
 * Función que elimina las variables persistentes sencillas creadas hasta el momento de su ejecución
 */
export async function clearGlobal() {
  try {
    await localStorage.clear();
    return "Deleted!";
  } catch (error) {}
}

/**
 * Función que retorna los valores almacenados en la variable persistente sencilla
 */
export async function getGlobal(key) {
  try {
    return getStoreItem(key);
  } catch (error) {}
}

/**
 * Función de escritura en variable sencilla persistente
 * @param {String} key
 * @param {array} data
 */
export async function setGlobal(key, data) {
  try {
    await setStoreItem(key, data);
  } catch (error) {}
}

async function callServer(method, url, data, token) {
  let response;
  let requestConfig = {
    "Ocp-Apim-Subscription-Key": CONST.API_KEY,
    Authentication: token
  };
  try {
    if (method == OP_CONST.GET) {
      response = await axios
        .get(url, {
          headers: requestConfig
        })
        .catch(function(error) {
          throw error;
        });
    } else if (method == OP_CONST.PUT) {
      response = await axios
        .put(url, {
          headers: requestConfig
        })
        .catch(function(error) {
          throw error;
        });
    } else if (method == OP_CONST.POST) {
      response = await axios
        .post(
          url,
          {
            data
          },
          {
            headers: requestConfig
          }
        )
        .catch(function(error) {
          throw error;
        });
    }
    return response;
  } catch (error) {}
}

/**
 * Retorna la información encontrada en la url del api manager con el uso de GET
 * @param {String} serviceName Posibles valores = {Urgencias, Profesionales, Otros, Prioritaria}
 *
 */

export async function callServices(serviceName, token) {
  try {
    const response = await axios.get(CONST.BASE_URL + serviceName, {
      headers: {
        "Ocp-Apim-Subscription-Key": CONST.API_KEY,
        Authentication: token
      }
    });
    console.log("Response--->",response);
    return response;
  } catch (error) {}
}

export async function callServicesNew(serviceName) {
  try {
    const response = await axios.get(CONST.BASE_URL + serviceName, {
      headers: {
        "Ocp-Apim-Subscription-Key": CONST.API_KEY
      }
    });
    return response;
  } catch (error) {}
}

/**
 *
 * @param {Int} operation Posibles valores = {1:GET ,2:PUT ,3:POST}
 * @param {String} serviceName Posibles valores = {Login, Habeas, Terminos}
 * @param {Object} params Estructura = {key : value}
 *
 */
export async function callServicesLogin(operation, serviceName, params) {
  let response;
  try {
    if (operation == OP_CONST.GET) {
      response = await axios.get(CONST.BASE_LOGIN_URL + serviceName, {
        headers: { "Ocp-Apim-Subscription-Key": CONST.API_KEY }
      });
    } else if (operation == OP_CONST.PUT) {
      response = await axios.put(
        CONST.BASE_LOGIN_URL + serviceName,
        {},
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONST.API_KEY
          }
        }
      );
    } else if (operation == OP_CONST.POST) {
      response = await axios.post(
        CONST.BASE_LOGIN_URL + serviceName,
        {
          TipoDoc: params.TipoDoc,
          Doc: params.Doc,
          Otp: params.Code
        },
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONST.API_KEY
          }
        }
      );
    }
    return response;
  } catch (error) {}
}

export async function callHeonServices(service, token) {
  try {
    const response = await axios.get(CONST.BASE_HEON_URL + service, {
      headers: {
        "Ocp-Apim-Subscription-Key": CONST.API_KEY,
        Authentication: token,
        "apiKey":CONST.API_KEY_HEON
      }
    });
    return response;
  } catch (error) {}
}

/**
 *
 * Retorna la información encontrada en la url del api manager del servicio de citas
 * @param {String} service
 *
 */
export async function callAppointmentService(method, service, token) {
  try {
    const response = await callServer(
      method,
      CONST.APPOINTMENT_URL + service,
      "",
      token
    );
    return response;
  } catch (error) {}
}

/**
 *
 * Retorna la información encontrada en la url del api manager del servicio de citas
 * @param {String} service
 *
 */
export async function callAsignAppointmentService(method, service, token) {
  /*try {
    const response = await callServer(
      method,
      CONST.ASIGN_APPOINTMENT_URL + service,
      "",
      token
    );*/
    /*
    response = await axios.post(
      CONST.BASE_LOGIN_URL + serviceName,
      {
        TipoDoc: params.TipoDoc,
        Doc: params.Doc,
        Otp: params.Code
      },
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONST.API_KEY
        }
      }
    );*/
    try {
      const response = await axios.post(CONST.APPOINTMENT_URL+service,{}, {
        headers: {
          "Ocp-Apim-Subscription-Key": CONST.API_KEY,
          Authentication: token,
          "apiKey":CONST.API_KEY_HEON
        }
      });
    return response;
  } catch (error) {}
}

/**
 *
 * Retorna la información encontrada en la url del api manager del servicio de listado de MEGA u OGA según la localidad y especialidad seleccionadas por GET
 * @param {int} idLocal
 * @param {int} idEsp
 *
 */
export async function callDoctorList(idLocal, idEsp, token) {
  try {
    const response = await axios
      .get(
        CONST.DOCTOR_URL +
          "?idLocalidad=" +
          idLocal +
          "&idTipoEspecialidad=" +
          idEsp,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONST.API_KEY,
            Authentication: token
          }
        }
      )
      .catch(function(error) {
        if (error.response) {
          console.error(error.response);
        }
      });
    return response;
  } catch (error) {}
}

/**
 *
 * Realiza el cambio de Médico MEGA u OGA por POST de cotizante o beneficiario
 * @param {object} doctor
 *
 */
export async function callChangeDoctor(doctor, idBeneficiary, token) {
  try {
    const idUser = idBeneficiary;

    const response = await axios
      .post(
        CONST.CHANGE_URL +
          "?idAfiliado=" +
          idUser +
          "&idProfesionalEspecialidad=" +
          doctor.IdProfesionalEspecialidad +
          "&idIps=" +
          doctor.IdPrestador +
          "&Origen=" +
          "Web",
        // "?idAfiliado=" +
        // 52963 +
        // "&idProfesionalEspecialidad=" +
        // 55 +
        // "&idIps=" +
        // 169 +
        // "&Origen=" +
        // "Android",
        {},
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONST.API_KEY,
            Authentication: token
          }
        }
      )
      .catch(function(error) {
        if (error.response) {
          console.error("");
        }
      });
    return response;
  } catch (error) {}
}

/**
 *
 * @param {String} someText
 *  Retorna las palabras con solo la primera letra en minúscula
 */
export function CapitalizeFirstLetter(someText) {
  if (typeof someText !== "string") {
    return someText;
  }
  var pieces = someText.split(" ");
  for (let index = 0; index < pieces.length; index++) {
    pieces[index] = pieces[index].toLowerCase();
    pieces[index] =
      pieces[index].charAt(0).toUpperCase() + pieces[index].slice(1);
  }
  var allText = pieces.join(" ");
  return allText;
}

export async function getUserService(service, token) {
  try {
    const response = await axios.get(CONST.BASE_LOGIN_URL + service, {
      headers: {
        "Ocp-Apim-Subscription-Key": CONST.API_KEY,
        Authentication: token
      }
    });
    return response;
  } catch (error) {}
}

export async function updateUserService(service, data, token) {
  try {
    const response = await axios.put(CONST.BASE_LOGIN_URL + service, data, {
      headers: {
        "Ocp-Apim-Subscription-Key": CONST.API_KEY,
        Authentication: token
      }
    });
    return response;
  } catch (error) {}
}

export async function getCMSService(service, token) {
  try {
    const response = await axios.get(CONST.BASE_CMS + service, {
      headers: {
        "Ocp-Apim-Subscription-Key": CONST.API_KEY,
        Authentication: token
      }
    });
    return response;
  } catch (error) {}
}

export async function createCMSService(service, data, token) {
  try {
    const response = await axios.post(CONST.BASE_CMS + service, data, {
      headers: {
        "Ocp-Apim-Subscription-Key": CONST.API_KEY,
        Authentication: token
      }
    });
    return response;
  } catch (error) {}
}

export async function updateCMSService(service, data, token) {
  try {
    const response = await axios.put(CONST.BASE_CMS + service, data, {
      headers: {
        "Ocp-Apim-Subscription-Key": CONST.API_KEY,
        Authentication: token
      }
    });
    return response;
  } catch (error) {}
}



export async function deleteCMSService(service, data, token) {
  try {
    const response = await axios.delete(CONST.BASE_CMS + service, {
      headers: {
        "Ocp-Apim-Subscription-Key": CONST.API_KEY,
        Authentication: token
      }
    });
    return response;
  } catch (error) {}
}

export async function getPHYService(service,token) {
  try {
    const response = await axios.get(CONST.BASE_PHY+service , {
      headers: {
        "Ocp-Apim-Subscription-Key": CONST.API_KEY,
        Authentication: token
      }
    });
    return response;
  } catch (error) {}
}

export async function createPHYService(service, data, token) {
  try {
    const response = await axios.post(CONST.BASE_PHY + service, data, {
      headers: {
        "Ocp-Apim-Subscription-Key": CONST.API_KEY,
        Authentication: token
      }
    });
    return response;
  } catch (error) {}
}

export async function updatePHYService(service, data, token) {
  try {
    const response = await axios.put(CONST.BASE_PHY + service, data, {
      headers: {
        "Ocp-Apim-Subscription-Key": CONST.API_KEY,
        Authentication: token
      }
    });
    return response;
  } catch (error) {}
}

/**
 * Envía una operación post al servicio de login
 * @param {string} service Nombre del servicio
 * @param {mixed} data Parámetros enviados al servidor
 * @param {string} token Token de acceso
 */
export async function createLoginService(service, data, token) {
  try {
    const response = await axios.post(CONST.BASE_LOGIN_URL + service, data, {
      headers: {
        "Ocp-Apim-Subscription-Key": CONST.API_KEY,
        Authentication: token
      }
    });
    return response;
  } catch (error) {}
}

/**
 * Función encargada de armar el objeto con la información de MEGA y OGA para cada usuario
 * para ser consumida desde el Home proviene desde Heon
 */
export async function getDataPhyHeon(onSession, token) {
  var PhyInfo = await callHeonServices(
    //"megaOga?tipoDocumento=2&nroDocumento=91278265&listaCompleta=true"
    //"megaOga?tipoDocumento=2&nroDocumento=63435420&listaCompleta=true"
    "megaOga?tipoDocumento=" +
      DOCUMENT_KEY[onSession.tipoDoc.toUpperCase()] +
      "&nroDocumento=" +
      onSession.doc +
      "&listaCompleta=true",
    token
  );
  console.log("getDataPhyHeon", PhyInfo);
  return PhyInfo;
}

export async function getDataUserHeon(onSession, token) {
  var userInfo = await callHeonServices(
    //"megaOga?tipoDocumento=2&nroDocumento=91278265&listaCompleta=true"
    //"megaOga?tipoDocumento=2&nroDocumento=63435420&listaCompleta=true"
    "megaOga?tipoDocumento=" +
      DOCUMENT_KEY[onSession.tipoDoc.toUpperCase()] +
      "&nroDocumento=" +
      onSession.doc +
      "&listaCompleta=true",
    token
  );
  console.log("getDataUserHeon", userInfo);
  if (!userInfo) {
    await setGlobal("HeonData", null);
    return null;
  }
  var infoD = userInfo.data;
  let dataUser = { mainUser: {}, beneficiaries: [] };
  if (userInfo.data.length === 0) {
    await setGlobal("HeonData", dataUser);
    return dataUser;
  }

  var index = 0;
  if (infoD.length > 1) {
    index = infoD
      .map(function(e) {
        return e.TipoAfiliado;
      })
      .indexOf("1");
  }

  dataUser.mainUser = infoD[index];
  infoD.splice(index, 1);
  dataUser.beneficiaries = infoD;

  if (onSession.FinVigencia != null && index >= 0) {
    var dateEnd =
      moment(onSession.FinVigencia).format("l") + " Vigencia reportada";
    dataUser.mainUser.FinVigencia = dateEnd;
  }

  dataUser.beneficiaries.forEach(element => {
    element.isAnAdult = howOld(element.FechaNacimiento);
  });
  await setGlobal("HeonData", dataUser);

  return dataUser;
}

/**
 *
 * Retorna la información encontrada en la url del api manager de los servicios de CMS por GET
 * @param {String} service Posibles valores = {faq,magazine}
 *
 */
export async function getUserHistories(service, token) {
  try {
    const response = await callHeonServices(
      service,
      token
    );
    return response;
  } catch (error) {}
}

/**
 *
 * Fecha del servidor en formato UTC
 *
 */
export async function getServerDate(token) {
  // var serverdate = getUrlParameter('serverdate');
  // if(!!serverdate && serverdate!=""){
  //   if(serverdate=='borrar'){
  //     localStorage.removeItem("serverdate");
  //   }else{
  //     localStorage.setItem("serverdate", serverdate);
  //     return(moment(serverdate, 'MM-DD-YYYY--HH:mm:ss'));
  //   }
  // }else{
  //   var storagedate = localStorage.getItem("serverdate");
  //   if(storagedate){
  //     return(moment(storagedate, 'MM-DD-YYYY--HH:mm:ss'));
  //   }
  // }

  let data = await getCMSService("magazine/fecha", token);
  let date = moment(data.data);
  let dateFrom = moment(date).subtract(5, "hours");
  return dateFrom;
}

/**
 *
 * @param {String} userBirth
 *  Retorna true o false dependiendo si la persona es mayor de edad o no.
 */
export function howOld(userBirth) {
  var today = new Date();
  var birthDate = new Date(userBirth);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age >= 18;
}

/**
 *
 * @param {String} someText
 *  Cambia saldos de línea por br.
 */

export function ConvertCharacter10(someText) {
  if (typeof someText !== "string") {
    return someText;
  }
  return someText.replace(/(\n)/gm, "<br />");
}
export function paramsToString(params) {
  params = params.filter(item => {
    return item.value !== null && item.value !== "";
  });
  return params.map(param => param.key + "=" + param.value).join("&");
}

export async function getAppointmentHistory(params, token) {
  let { tipoDocumento, nroDocumento, fechaInicio, fechaFinal } = params;
  params = [
    {
      key: "tipoDocumento",
      value: tipoDocumento
    },
    {
      key: "nroDocumento",
      value: nroDocumento
    },
    {
      key: "fechaInicio",
      //value: '2018-10-01'
      value: fechaInicio
    },
    {
      key: "fechaFinal",
      //value: '2018-10-02'
      value: fechaFinal
    }
  ];
  params = paramsToString(params);
  return await getUserHistories("historicoCitasMedicas?" + params, token);
}
export async function cancelAppointment(params, token) {
  let { idCita, motivoCancelacion } = params;
  params = [
    {
      key: "idCita",
      value: idCita
    },
    {
      key: "motivoCancelacion",
      value: motivoCancelacion
    }
  ];
  params = paramsToString(params);
  return await callAsignAppointmentService(
    OP_CONST.POST,
    "CancelarCita?" + params,
    token
  );
}

/**
 *
 * @param {String} email String a validar si es un correo electrónico valido
 *  Función que determina si la estructura de un correo es correcta
 */
export function ValidateEmail(email) {
  var re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(email);
}

function getUrlParameter(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

async function callServer2(method, url, data, customHeaders, options) {
  let currentUser = store.getState().auth.auth;

  let token = store.getState().auth.auth.token;

  let userLog = "";
  if (currentUser.isAdmin != undefined) {
    userLog = currentUser.isAdmin
      ? currentUser.user.email
      : currentUser.user.documentType + currentUser.user.documentId;
  }

  let headers = {
    "Ocp-Apim-Subscription-Key": CONST.API_KEY,
    Authentication: token,
    "User-Log": btoa(userLog),
    ...customHeaders
  };
  return await axios({
    headers,
    method,
    url: url,
    data,
    options
  });
}

/**
 *
 * Retorna la información encontrada en la url del api manager del servicio de citas
 * @param {String} service
 *
 */
export async function callLoginService(method, data, service) {
  try {
    const response = await callServer2(
      method,
      CONST.BASE_ADMIN_URL + service,
      data
    );
    return response;
  } catch (error) {}
}

/**
 *
 * Retorna la información encontrada en la url del api manager del servicio de citas
 * @param {String} service
 *
 */
export async function callHabeasService(method, data) {
  try {
    const response = await callServer2(method, CONST.BASE_HABEAS, data);
    return response;
  } catch (error) {}
}

/**
 *
 * @param {Int} method Posibles valores = {'get':GET ,'put':PUT ,'post':POST}
 * @param {String} serviceName Posibles valores = {Login, Habeas, Terminos}
 * @param {Object} params Estructura = {key : value}
 * Obtiene las viceepresidencias
 */
export async function callAdminServices(
  method,
  serviceName,
  params,
  customHeaders,
  options
) {
  return callServer2(
    method,
    CONST.ADMIN_URL + serviceName,
    params,
    customHeaders,
    options
  );
}

/**
 * Usa el servicio de envío de notificaciones
 * @param {*} token Token de autenticación
 * @param {*} data Parámetros obligatorios para el servicio
 * @param {*} queueTime Opcional. Fecha y hora para programar una notificación
 */
export async function sendNotification(
  token,
  { id, emailTo, names, type, documentId, dateString },
  queueTime
) {
  const queueTimeParams = !!queueTime ? { queueTime } : {};
  console.log("sendNotification", {
    data: { id, emailTo, names, type, documentId, dateString },
    queueTime
  });
  return createLoginService(
    "notificaciones/enviar",
    {
      id,
      emailTo,
      names,
      type,
      documentId,
      dateString,
      ...queueTimeParams
    },
    token
  );
}
